import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-termos',
  standalone: true,
  imports: [MatDialogModule, CommonModule, MatButtonModule],
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss']
})
export class TermosComponent implements OnInit {
  user$: Observable<any>;
  @Output() acceptEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    console.log('tela de termos');
    this.user$ = this.loginService.user;
  }

  onAccept() {
    this.acceptEvent.emit(true);
  }

  onRefuse() {
    this.acceptEvent.emit(false);
  }
}
