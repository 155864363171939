<div class="full-container">
    <form class="card" [formGroup]="loginForm">
        <h4 class="subtitle">Acesse sua conta</h4>
        
        <div class="input-content">
            <mat-icon [ngClass]="{'invalid-feedback': !loginForm.controls.login.valid }" style="font-size: 30px !important;">person</mat-icon>
            <input wcInputType="name" formControlName="login" placeholder="Insira seu CPF/CNPJ">
        </div>
        <div class="input-content" style="margin-top: 25px;">  
            <mat-icon  [ngClass]="{'invalid-feedback': !loginForm.controls.password.valid }">lock</mat-icon>
            <input  formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="Insira sua senha">
            <!-- <mat-icon matSuffix style="cursor: pointer;" (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon> -->
        </div>
    
        <button class="btn btn-login" style="color: #FFF;" (click)="login()">Avançar</button>

        <div style="display: flex; justify-content: center;">
            <a [routerLink]="['/redefinir-senha']" routerLinkActive="router-link-active"  class="forget-password-link">Esqueceu a senha?</a>
        </div>
    </form>
    <!-- <div style=" margin-left: 35%; margin-top: 40px;">
        <span class="new-account-link">Não tem cadastro? <a [routerLink]="['/nova-conta']">Cadastre-se aqui</a></span>
    </div> -->
</div>

<!-- <ngx-spinner type="ball-atom"></ngx-spinner> -->
