<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-container *ngIf="user$ | async; let user">
    <div class="full-container">
        <div class="box">
            <div>
                <h1 style="margin: 10px 20px" class="text-primary">Olá, {{user.full[0].name}}.</h1>
                <h3 style="margin: 30px 20px 0; text-align: center;">Aceite os termos
                    para
                    prosseguir</h3>
            </div>
            <div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-top: 30px;">
                <form [formGroup]="form" *ngIf="form">
                    <div class="w-full">
                        <input formControlName="termo" type="checkbox"
                            class="w-4 h-4 bg-gray-100 border-gray-300 rounded">
                        <label for="termo" class="ml-2 font-normal text-sm text-gray-600"> Eu li e concordo com os
                            <span class="text-danger" style="text-decoration: underline; cursor: pointer;"
                                (click)="showTermos()">
                                termos de uso
                            </span></label>
                    </div>
                </form>
                <div style="display: flex; justify-content: flex-end; width: 100%; margin-top: 30px;">
                    <button [ngClass]="{'opacity-50 cursor-not-allowed': !form.valid}"
                        class="w-auto bg-primary-500 hover:bg-primary-600 text-white font-semibold rounded-lg px-5 py-2"
                        [disabled]="!form.valid" (click)="next()">Prosseguir</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>