import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TelephoneMaskDirective } from 'src/app/shared/directives/telephone-mask-directive';
import { MatIconModule } from '@angular/material/icon';
import { Utils } from 'src/app/shared/utils';
import { UiService } from 'src/app/services/ui-service';
import { LeadsService } from 'src/app/services/leads.service';
import { CpfCnpjMaskDirective } from 'src/app/shared/directives/cpf-cnpj-mask-directive';

@Component({
  selector: 'app-agent-seller-lead',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CpfCnpjMaskDirective,
    TelephoneMaskDirective,
    MatIconModule
  ],
  templateUrl: './agent-seller-lead.component.html',
  styleUrls: ['./agent-seller-lead.component.scss']
})
export class AgentSellerLeadComponent {

  form: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    cnpj: new FormControl(null, [Validators.required, Validators.minLength(13)]),
    phone: new FormControl(null, [Validators.required, Validators.minLength(11)]),
    email: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
  });
  
  constructor(
    private uiService: UiService,
    private leadsService: LeadsService
  ) { }

  async onSubmit() {
    const form = this.form.value;
    form.cnpj = Utils.OnlyNumber(form.cnpj);
    form.phone = Utils.OnlyNumber(form.phone);
    try {
      this.uiService.showSwal('Aguarde', '', true, 'info');
      await this.leadsService.saveLead(form);
      this.uiService.showSwal('Sucesso', 'Formulário enviado com sucesso!', false, 'success');
      this.form.reset();
    } catch (error) {
      this.uiService.showSwal('Erro', error.error.message, false, 'error');
    }
  }
}
