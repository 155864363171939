<div class="full-container">
    <form *ngIf="token; else newPasswordForm" class="card" [formGroup]="passwordForm">
        <h5 class="subtitle">Redefinir senha</h5>

        <div class="input-content" style="margin-top: 25px;">
            <mat-icon [ngClass]="{'invalid-feedback': !passwordForm.controls.newPassword.valid}">lock</mat-icon>
            <input formControlName="newPassword" [type]="showPassword ? 'text' : 'password'"
                placeholder="Insira a sua nova senha">
            <mat-icon matSuffix style="cursor: pointer;" (click)="showPassword = !showPassword">
                {{showPassword ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </div>

        <mat-hint style="padding-left: 6px;">A senha deve ter no mínimo 6 caracteres</mat-hint>

        <div class="input-content" style="margin-top: 25px;">
            <mat-icon [ngClass]="{'invalid-feedback': !passwordForm.controls.confirmPassword.valid}">lock</mat-icon>
            <input formControlName="confirmPassword" [type]="showPassword ? 'text' : 'password'"
                placeholder="Confirme sua nova senha">
            <mat-icon matSuffix style="cursor: pointer;" (click)="showPassword = !showPassword">
                {{showPassword ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </div>

        <button [disabled]="!passwordForm.valid" class="btn btn-primary" (click)="onUpdatePassword()">
            Salvar senha
        </button>
    </form>

    <ng-template #newPasswordForm>
        <form class="card" [formGroup]="documentForm">
            <h4 class="subtitle">Redefinir senha</h4>

            <div *ngIf="step === 0" class="input-content">
                <mat-icon [ngClass]="{'invalid-feedback': !documentForm.controls.document.valid }"
                    style="font-size: 30px !important;">
                    person
                </mat-icon>
                <input wcInputType="name" formControlName="document" appCpfCnpj placeholder="Insira seu CPF/CNPJ">
            </div>

            <button *ngIf="step === 0" style="margin-top: 18px;" [disabled]="!documentForm.controls.document.valid"
                class="btn btn-primary" (click)="sendCode()">
                <span *ngIf="!loadingProcess">
                    Enviar código
                </span>
                <mat-spinner [diameter]="21" *ngIf="loadingProcess"></mat-spinner>
            </button>



            <div *ngIf="step === 1" class="input-content">
                <mat-icon [ngClass]="{'invalid-feedback': !documentForm.controls.validation_code.valid }"
                    style="font-size: 30px !important;">
                    lock
                </mat-icon>
                <input maxlength="8" formControlName="validation_code" placeholder="Insira o código de validação">

            </div>
            <small *ngIf="step === 1" style="font-size: 12px; margin-top: 2px; color: gray;">
                O código chegará em seu celular via SMS
            </small>

            <button *ngIf="step === 1" style="margin-top: 18px;"
                [disabled]="!documentForm.controls.validation_code.valid" class="btn btn-primary"
                (click)="validateCode()">
                <span *ngIf="!loadingProcess">
                    Validar código
                </span>
                <mat-spinner [diameter]="21" *ngIf="loadingProcess"></mat-spinner>
            </button>

            <div *ngIf="step === 2" class="input-content">
                <mat-icon [ngClass]="{'invalid-feedback': !documentForm.controls.new_password.valid }"
                    style="font-size: 30px !important;">
                    lock
                </mat-icon>
                <input maxlength="8" formControlName="new_password" placeholder="Insira a nova Senha">
            </div>

            <button *ngIf="step === 2" style="margin-top: 18px;" [disabled]="!documentForm.controls.new_password.valid"
                class="btn btn-primary" (click)="changePassword()">
                <span *ngIf="!loadingProcess">
                    Alterar senha
                </span>
                <mat-spinner [diameter]="21" *ngIf="loadingProcess"></mat-spinner>
            </button>

            <h4 *ngIf="step === 3">Senha alterada com sucesso!</h4>

            <button [routerLink]="['/login']" *ngIf="step === 3" style="margin-top: 18px;" class="btn btn-primary">
                Entendi
            </button>

            <h4 *ngIf="step === -1" class="text-danger" align="center">{{errorMsg}}</h4>

            <button *ngIf="step === -1" style="margin-top: 18px; padding: 16px ;" class="btn btn-primary"
                (click)="retry()">
                <span>
                    Tentar novamente
                </span>
                <mat-spinner [diameter]="21" *ngIf="loadingProcess"></mat-spinner>
            </button>


            <!-- <button [disabled]="!documentForm.valid" class="btn btn-primary" (click)="onSendMail()">
                Enviar e-mail
            </button> -->
        </form>
    </ng-template>
</div>