// import { DialogConfirmationData, DialogConfirmationComponent } from './../shared/dialog-confirmation/dialog-confirmation.component';
import { Injectable, Inject } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class UiService {
  showSwal(title, text, loading = false, icon, html = false, htmlData?){
    if(loading) {
      if(text === ''){
        return Swal.fire({
          title: title,
          heightAuto: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn-swal',
          },
          buttonsStyling: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
      }
      else{
        return Swal.fire({
          title: title,
          text: text,
          heightAuto: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn-swal',
          },
          buttonsStyling: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
      }
    } 
    else if(html){
      return Swal.fire({
        title: title,
        heightAuto: false,
        html: htmlData,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-swal',
        },
      });

    }
    else{
     return  Swal.fire({
        icon: icon,
        title: title,
        heightAuto: false,
        text: text,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-swal',
        },
      });
    }
  }

 

  closeSwal(){
    Swal.close();
  }

  
}
