import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login/login.guard';
import { FirstAccessComponent } from './pages/first-access/first-access.component';
import { AgentSellerLeadComponent } from './pages/agent-seller-lead/agent-seller-lead.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'activate', component: FirstAccessComponent },
  { path: 'quero-ser-revendedor', component: AgentSellerLeadComponent },
  { path: 'redefinir-senha', component: ResetPasswordComponent },
  { path: 'redefinir-senha/:token', component: ResetPasswordComponent },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.routes').then(m => m.HOME_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'vendedores',
    loadChildren: () => import('./pages/vendedor/vendedor.routes').then(m => m.VENDEDOR_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/cliente/cliente.routes').then(m => m.CLIENTE_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'vendas',
    loadChildren: () => import('./pages/cliente/cliente.routes').then(m => m.CLIENTE_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'revendedores',
    loadChildren: () => import('./pages/revendedor/revendedor.routes').then(m => m.REVENDEDOR_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'parceiros',
    loadChildren: () => import('./pages/parceiro/parceiro.routes').then(m => m.PARCEIRO_ROUTES),
    canActivate: [LoginGuard]
  },
  {
    path: 'share-link',
    loadChildren: () => import('./pages/share-link/share-link.routes').then(m => m.LINK_ROUTES),
    canActivate: [LoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
