<ngx-spinner name="global" size="large" color="#1cbc73 " type="ball-atom" [fullScreen]="true">
    <p style="padding-top: 60px; color: #FFF; font-size: 16px;">Por favor, aguarde...</p>
</ngx-spinner>


<div
    [ngClass]="{'bg-white': user?.type !== 10, 'bg-primary-800': user?.type === 10}"
    class="w-full h-14 flex flex-row justify-between items-center bg-white"
    style="box-shadow:12px 12px 20px 0px rgba(0, 0, 0, 0.1); padding: 0 32px;">
    <img *ngIf="user?.type === 10" class="h-9" src="../assets/Logomarca/Logo_sem_slogan_branca.png" alt="Consultei logo">
    <img *ngIf="user?.type !== 10" class="h-9" src="../assets/Logomarca/Logo_sem_slogan.png" alt="Consultei logo">
    <div *ngIf="user" style="display: flex; gap: 32px; font-size: 14px;">
        <button *ngFor="let r of routes" class="menu-link" routerLink="{{r.path}}" routerLinkActive="active-link" [ngClass]="{'text-gray-200': user?.type === 10, 'text-neutral-dark-grey-800': user?.type !== 10}">
            <mat-icon class="menu-icon">{{r.icon}}</mat-icon>
            {{r.label}}
        </button>
    </div>
    
    <!-- <div (click)="logout()" *ngIf="user" class="content-user" style="display: flex; align-items: center; gap: 8px; height: 40px; padding: 0 8px; background-color: #e1e1e1; border-radius: 8px;">
        <img style="height: 30px; width: 30px; border-radius: 50%;" src="../assets/Logomarca/Elemento_oficial.png" alt="">
        <span style="font-size: 14px;">Nome do usuario</span>
    </div> -->

    <button (click)="logout()" *ngIf="user"  class="btn-logout font-medium bg-primary-500" (click)="logout()">
        Logout
    </button>


    <button *ngIf="!user && openedRoute !== 'quero-ser-revendedor'" [routerLink]="['/quero-ser-revendedor']"  class="btn-logout font-medium bg-tertiary-500">
        Quero ser revendedor
    </button>

    <button *ngIf="!user && openedRoute === 'quero-ser-revendedor'" [routerLink]="['/']"  class="btn-logout font-medium bg-tertiary-500">
        Já tenho cadastro
    </button>

</div>

<div class="w-full overflow-y-auto" style="height: calc(100vh - 64px);">
    <router-outlet></router-outlet>
</div>