import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { HeaderService } from '../services/header.service';
import { Utils } from '../shared/utils';

@Injectable({
    providedIn: "root"
})

export class LoginService {

    apiUrl = environment.apiUrl;
    private _user = new BehaviorSubject<any>(false);

    constructor(
        private router: Router,
        private http: HttpClient,
        private headerService: HeaderService,
    ) {
        this.profile().subscribe();
    }

    get user() {
        return this._user.asObservable();
    }

    setUser(user) {
        this._user.next(user);
    }

    login(email, senha) {
        return this.headerService.header.pipe(
            switchMap((header: any) => {
                const body = new URLSearchParams;
                body.set("login", email);
                body.set("password", senha);

                return this.http.post(`${this.apiUrl}/auth/login`, body.toString(), header).pipe(
                    switchMap((result: any) => {
                        localStorage.setItem('access_token', result["access_token"]);
                        localStorage.setItem('time_token', `${((new Date().getTime()) / 1000)}`);
                        return of(result);
                    })
                );
            })
        );
    }

    updateFirstAccess(userId) {
        return this.headerService.header.pipe(
            take(1),
            switchMap((header: any) => {
                const body = Utils.GetFilledValues({ id: userId }).toString();
                return this.http.put(`${this.apiUrl}/users/updateFirstAccess`, body, header);
            })
        );
    }

    logout() {
        return this.headerService.header.pipe(
            switchMap((header: any) => {
                return this.http.get(`${this.apiUrl}/auth/logout`, header)
                    .pipe(map(result => {
                        localStorage.removeItem('access_token');
                        this.router.navigate(['/login']);
                    }));
            })
        );
    }

    profile() {
        return this.headerService.header.pipe(
            switchMap((header: any) => {
                return this.http.get(`${this.apiUrl}/users/me`, header);
            }),
            map((result: any) => {
                if (result) this.setUser(result);
                return result;
            })
        );
    }
}