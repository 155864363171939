import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TermosComponent } from 'src/app/components/termos/termos.component';
import { LoginService } from 'src/app/login/login.service';
import { first, firstValueFrom } from 'rxjs';
import { UiService } from 'src/app/services/ui-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-access',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: './first-access.component.html',
  styleUrls: ['./first-access.component.scss']
})
export class FirstAccessComponent {
  user$ = this.loginService.user;

  form = new FormGroup({
    termo: new FormControl(null, Validators.requiredTrue),
  });

  constructor(
    private loginService: LoginService,
    private dialog: MatDialog,
    private uiService: UiService,
    private router: Router
  ) { }

  async next() {
    try {
      const user = await firstValueFrom(this.user$.pipe(first()));
      this.uiService.showSwal('Aguarde', '', true, 'info');
      const result = await firstValueFrom(this.loginService.updateFirstAccess(user.id));
      if (!result) throw new Error('Erro ao atualizar o primeiro acesso');
      this.uiService.showSwal('Sucesso', 'Acesso confirmado!', false, 'success');
      this.router.navigate(['/home']);
    } catch (error) {
      this.uiService.showSwal('Erro', error.error.message, false, 'error');
    }
  }

  showTermos() {
    const dialogRef = this.dialog.open(TermosComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      this.form.get('termo').setValue(result);
    });
  }
}
