import { Component } from '@angular/core';
import { LoginService } from './login/login.service';
import { UiService } from './services/ui-service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, finalize } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'consultei-vendas';
  user = null;
  ROUTES = [
    { path: 'home', label: 'Dashboard', icon:"dashboard", userTypes: [8, 9, 10] },
    { path: 'vendedores', label: 'Meus vendedores', icon:"group", userTypes: [8] },
    { path: 'vendas', label: 'Vendas', icon:"shopping_cart", userTypes: [8]},
    // { path: 'acompanhamento', label: 'Acompanhamento', userTypes: [9] },
    { path: 'clientes', label: 'Meus clientes', icon:"group", userTypes: [9]},
    { path: 'share-link', label: 'Link', icon:"link", userTypes: [9]},

    { path: 'parceiros', label: 'Parceiros', icon:"handshake", userTypes: [10]},
    { path: 'revendedores', label: 'Revendedores', icon:"group", userTypes: [10]},
    // { path: 'clientes', label: 'Leads', icon:"link", userTypes: [10]},
  ];

  routes;
  openedRoute = null;

  constructor(
    private loginSvc: LoginService,
    private uiService: UiService,
    private router: Router,
  ) {
    
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.openedRoute = event.url.split('/')[1];
    })


    this.loginSvc.user.subscribe(user => {
      this.routes = this.ROUTES.filter(route => route.userTypes.includes(+user.type));
      this.user = user;
    });
  }

  logout() {
    this.uiService.showSwal("Aguarde...", '', true, '');
    this.loginSvc.logout().pipe(
      finalize(() => {
        this.user = null;
        this.uiService.closeSwal();
      })
    ).subscribe(() => { }, () => this.router.navigate(['']));
  }
}
