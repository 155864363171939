<div class="h-full overflow-y-auto w-full flex flex-col items-center bg-neutral-light-grey-500">
    <div class="md:w-11/12 lg:w-10/12 xl:w-8/12 2xl:w-7/12 h-fit mt-12">
        <div class="bg-white shadow-lg rounded-3xl px-8 pt-6 pb-8 mb-4 flex flex-col items-center">
            <h1 style="font-weight: 500 !important; margin-bottom:4px" class="text-center text-xl mb-4 text-primary-500">
                Tem interesse em ser um agente de vendas?
            </h1>
            <h2 style="font-weight: 300 !important; font-size: 16px; " class="text-center text-xl mb-4 text-neutral-dark-grey-400">
                Preencha o formulário abaixo e entraremos em contato com você!
            </h2>

            <form class="md:w-11/12 lg:w-10/12 xl:w-8/12 2xl:w-7/12 mt-7" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="w-full relative block">
                    <mat-icon
                        class="text-tertiary-900 pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3">person</mat-icon>
                    <input class="pl-12 h-12" placeholder="Insira o nome do responsável" formControlName="name"
                        type="text" />
                </div>

                <div class="w-full relative block mt-4">
                    <mat-icon
                        class="text-tertiary-900 pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3">verified</mat-icon>
                    <input class="pl-12 h-12" appCpfCnpj placeholder="Insira seu CPF ou CNPJ" formControlName="cnpj"
                        type="text" />
                </div>

                <div class="w-full relative block mt-4">
                    <mat-icon
                        class="text-tertiary-900 pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3">location_on</mat-icon>
                    <input class="pl-12 h-12" placeholder="Insira sua cidade" formControlName="city" type="text" />
                </div>

                <div class="w-full relative block mt-4">
                    <mat-icon
                        class="text-tertiary-900 pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3">mail</mat-icon>
                    <input class="pl-12 h-12" placeholder="Insira seu e-mail" formControlName="email" type="text" />
                </div>

                <div class="w-full relative block mt-4">
                    <mat-icon
                        class="text-tertiary-900 pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3">call</mat-icon>
                    <input class="pl-12 h-12" appTelephoneMask placeholder="Insira seu telefone ou celular de contato"
                        formControlName="phone" type="text" />
                </div>
            </form>

            <div class="w-full mt-8 flex justify-end gap-3">
                <!-- <button type="reset"
                    class="w-auto bg-red-400 hover:bg-red-500 text-white font-semibold rounded-lg px-5 py-2">
                    Limpar
                </button> -->
                <button (click)="onSubmit()" [disabled]="!form.valid"
                    [ngClass]="{'opacity-50 cursor-not-allowed': !form.valid}"
                    class="w-auto bg-primary-500 hover:bg-primary-600 text-white font-semibold rounded-lg px-8 py-2">
                    Enviar dados
                </button>
            </div>
        </div>
    </div>
</div>