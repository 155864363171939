import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { HeaderService } from 'src/app/services/header.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedefinirSenhaService {

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  sendMail(document: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/changeEmailPassword`, { cpfCnpj: document });
  }

  updatePassword(newPassword: string, token: string): Observable<any> {
    return this.headerService.headerJson.pipe(
      switchMap((header: any) => this.http.post(`${this.apiUrl}/users/changeEmailPassword`, { newPassword, tokenJwt: token }, header))
    );
  }

  generatePasswordChangeToken(cpfCnpj: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/generatePasswordChangeToken`, { cpfCnpj });
  }

  verifyPasswordChangeToken(cpfCnpj: any, code: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/verifyPasswordChangeToken`, { cpfCnpj, passwordToken: code });
  }

  changePasswordWithToken(cpfCnpj: any, code: any, password: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/changePasswordWithToken`, { cpfCnpj, passwordToken: code, newPassword: password });
  }

}
