import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { catchError, of } from 'rxjs';
import { UiService } from 'src/app/services/ui-service';
import { confirmPasswordValidator } from 'src/app/shared/directives/confirm-password.directive';
import { RedefinirSenhaService } from './redefinir-senha.service';
import { Utils } from 'src/app/shared/utils';
import { CpfCnpjMaskDirective } from 'src/app/shared/directives/cpf-cnpj-mask-directive';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CpfCnpjMaskDirective
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  documentForm: FormGroup;
  passwordForm: FormGroup;
  showPassword = false;
  token: string = undefined;
  loadingProcess;
  errorMsg;
  showError;
  step = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private redefinirSenhaServie: RedefinirSenhaService,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.token = params?.token;
    });

    this.documentForm = this.formBuilder.group({
      document: ['', [Validators.required, Validators.minLength(14)]],
      validation_code: ['', [Validators.required, Validators.minLength(6)]],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        confirmPasswordValidator('confirmPassword', 'confirmPassword')
      ]],
      confirmPassword: ['', [Validators.required, confirmPasswordValidator('newPassword', 'confirmPassword')]]
    });

    console.log('valid:');
    console.log(this.passwordForm.controls.newPassword.valid);
  }

  onSendMail(): void {
    this.uiService.showSwal('Carregando...', '', true, undefined);

    this.redefinirSenhaServie.sendMail(Utils.RemoveSpecialCharacters(this.documentForm.get('document').value)).pipe(
      catchError((err: any) => {
        this.uiService.closeSwal();
        this.uiService.showSwal('Erro', `${err.error}`, false, 'error');
        return of();
      })
    )
      .subscribe((res: any) => {
        this.uiService.closeSwal();
        this.uiService.showSwal('Sucesso', `${res}`, false, 'success');
      });
  }

  onUpdatePassword(): void {
    this.uiService.showSwal('Carregando...', '', true, undefined);

    this.redefinirSenhaServie.updatePassword(this.passwordForm.get('newPassword').value, this.token).pipe(
      catchError((err: any) => {
        this.uiService.closeSwal();
        this.uiService.showSwal('Erro', `${err.error}`, false, 'error');
        return of();
      })
    )
      .subscribe((res: any) => {
        this.uiService.closeSwal();
        this.uiService.showSwal('Sucesso', `${res}`, false, 'success').then((sweetRes: any) => {
          this.router.navigateByUrl('/login');
        });
      });
  }

  sendCode() {
    this.loadingProcess = true;

    this.redefinirSenhaServie.generatePasswordChangeToken(Utils.RemoveSpecialCharacters(this.documentForm.get('document').value))
      .subscribe((res: any) => {
        this.loadingProcess = false;
        this.step = 1;
      }, (err: any) => {
        this.loadingProcess = false;
        this.showError = true;
        this.errorMsg = err.error;
        this.step = -1;
        // debugger
      });

  }

  validateCode(): void {
    this.loadingProcess = true;
    this.redefinirSenhaServie.verifyPasswordChangeToken(Utils.RemoveSpecialCharacters(this.documentForm.get('document').value), this.documentForm.get('validation_code').value)
      .subscribe((res: any) => {
        if (res.canChangePassword) {
          this.loadingProcess = false;
          this.step = 2;
        }
        else {
          this.loadingProcess = false;
          this.showError = true;
          this.errorMsg = 'Código inválido...';
          this.step = -1;
        }
      }, (err: any) => {
        this.loadingProcess = false;
        this.showError = true;
        this.errorMsg = err.error;
        this.step = -1;
      });
  }

  changePassword(): void {
    this.loadingProcess = true;
    this.redefinirSenhaServie.changePasswordWithToken(
      Utils.RemoveSpecialCharacters(this.documentForm.get('document').value),
      this.documentForm.get('validation_code').value,
      this.documentForm.get('new_password').value
    ).subscribe((res: any) => {
      this.loadingProcess = false;
      this.step = 3;

    }, (err: any) => {
      this.loadingProcess = false;
      this.showError = true;
      this.errorMsg = err.error;
      this.step = -1;
    });
  }

  retry() {
    this.errorMsg = null;
    this.showError = false;
    this.step = 0;
  }
}
