import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, switchMap, take } from "rxjs";
import { environment } from "src/environments/environment";
import { HeaderService } from "./header.service";
import { Utils } from "../shared/utils";

@Injectable({
    providedIn: 'root'
})
export class LeadsService {
    apiUrl = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private headerService: HeaderService,
    ) { }

    saveLead(lead: any) {
        lead.isAgent = true
        lead.isCompany = false
        lead.cpfCnpj = lead.cnpj
        return firstValueFrom(this.headerService.header.pipe(
            take(1),
            switchMap((header: any) => {
                const body = Utils.GetFilledValues(lead).toString();
                return this.http.post(`${this.apiUrl}/leads/save`, body, header);
            })
        ));
    }
}