<mat-dialog-content style="max-width: 800px" class="mat-typography">
  <ng-container *ngIf="(user$ | async)?.type !== 1; else termosMedico">
    <p style="text-align: center;">
      <strong>TERMOS DE SERVI&Ccedil;O E CONDI&Ccedil;&Otilde;ES DE USO</strong>
    </p>
    <ol>
      <li>
        <strong>INTRODU&Ccedil;&Atilde;O</strong><strong><br /><br /></strong>
      </li>
    </ol>
    <p>
      1.1. Seja bem-vindo a plataforma
      <strong>Consultei Telemedicina e Tecnologia Ltda</strong>
      (&ldquo;Consultei&rdquo;). De acordo com os termos de servi&ccedil;o
      abaixo descritos, o Usu&aacute;rio estar&aacute; ciente dos seus direitos
      e deveres legais para uso da Plataforma <strong>CONSULTEI</strong> de
      propriedade de <strong>CONSULTEI</strong>, pessoa jur&iacute;dica de
      direito privado, inscrita no CNPJ sob o n&ordm; 44.627.285/0001-60, com
      sede na Rua Santana, 176 &ndash; Centro &ndash; Itapeva/SP, CEP:
      18.400-010.
    </p>
    <p>
      1.2. &Eacute; imprescind&iacute;vel que os Termos e
      Condi&ccedil;&otilde;es de Utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI</strong> sejam lidos integralmente e com
      aten&ccedil;&atilde;o para, ao final, se o Usu&aacute;rio ainda quiser
      proceder e utilizar os servi&ccedil;os da Plataforma, aceita-lo.
    </p>
    <p>
      1.3. Ao utilizar os servi&ccedil;os da Plataforma
      <strong>CONSULTEI</strong> o Usu&aacute;rio CONCORDA e CONSENTE com os
      Termos e Condi&ccedil;&otilde;es de Uso, representando concord&acirc;ncia
      integral com o quanto aqui estabelecido e ter&aacute; vig&ecirc;ncia
      durante todo o per&iacute;odo de uso, n&atilde;o cabendo ao Usu&aacute;rio
      alegar o desconhecimento de seus Termos e Condi&ccedil;&otilde;es.
    </p>
    <p>
      1.4. Caso o Usu&aacute;rio n&atilde;o concorde com qualquer uma das
      disposi&ccedil;&otilde;es ora estabelecidas, ele n&atilde;o dever&aacute;
      utilizar a Plataforma <strong>CONSULTEI,</strong> neste caso, o uso da
      Plataforma <strong>CONSULTEI</strong> n&atilde;o est&aacute; autorizado.
    </p>
    <p>
      1.5. Em caso de discord&acirc;ncia posterior dos Termos e
      Condi&ccedil;&otilde;es de Uso da Plataforma, o seu uso dever&aacute; ser
      imediatamente interrompido, n&atilde;o sendo autorizado a
      manuten&ccedil;&atilde;o do seu uso sem pr&eacute;via e expressa
      aceita&ccedil;&atilde;o.
    </p>
    <p>
      1.6. A qualquer momento a Plataforma poder&aacute; sofrer
      altera&ccedil;&otilde;es, suspens&atilde;o ou encerramento de qualquer de
      suas partes, assim como impor limites a certos recursos ou restringir seu
      acesso a partes dele ou a ele como um todo a seu exclusivo crit&eacute;rio
      e sem aviso ou responsabilidade.
    </p>
    <p>
      1.7. O acesso &agrave; Plataforma poder&aacute; ser suspenso caso seja
      constatado que o seu uso poder&aacute; levar &agrave;
      viola&ccedil;&atilde;o destes Termos de Servi&ccedil;o e/ou lei e
      regulamentos aplic&aacute;veis.
    </p>
    <ol start="2">
      <li><strong> CONCEITOS</strong></li>
    </ol>
    <p>
      Os termos expressos neste documento ter&atilde;o o seguinte significado:
    </p>
    <p>
      Plataforma <strong>CONSULTEI</strong><strong>:</strong><em>Software </em>de titularidade da
      <strong>CONSULTEI</strong>,
      denominado &ldquo; <strong>CONSULTEI</strong>&rdquo;, em formato web ou de
      Aplicativo a permitir a pr&aacute;tica da Telemedicina no atendimento aos
      Usu&aacute;rios por meio de Teleconsultas de v&iacute;deo, voz e chat.
    </p>
    <p>
      Assinatura Mensal: esp&eacute;cie de ades&atilde;o do Usu&aacute;rio aos
      Servi&ccedil;os da <strong>CONSULTEI</strong>, cujos valores
      estar&atilde;o dispostos em local vis&iacute;vel na Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      Profissionais de Sa&uacute;de: M&eacute;dicos e demais profissionais da
      sa&uacute;de devidamente registrados em seus respectivos
      &oacute;rg&atilde;os/conselhos de classe, que prestar&atilde;o
      servi&ccedil;os de Teleconsulta aos Usu&aacute;rios.
    </p>
    <p>
      Servi&ccedil;os: Servi&ccedil;os de licenciamento e
      intermedia&ccedil;&atilde;o destinados a interconex&atilde;o
      <em>online</em> entre Profissionais de Sa&uacute;de e os Usu&aacute;rios,
      por meio da Plataforma <strong>CONSULTEI </strong>para Teleconsulta
      mediante Assinatura Mensal ou Avulsa.
    </p>
    <p>
      Teleconsulta: consulta m&eacute;dica realizada &agrave; dist&acirc;ncia
      via internet, com o aux&iacute;lio da tecnologia da
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      Teleconsulta Avulsa: consulta m&eacute;dica realizada &agrave;
      dist&acirc;ncia via internet, com o aux&iacute;lio da tecnologia da
      <strong>CONSULTEI</strong>, por meio dos Profissionais de Sa&uacute;de
      diretamente vinculados &agrave; Institui&ccedil;&otilde;es, de modo
      alternativo e n&atilde;o vinculado &agrave; Assinatura Mensal, cujos
      valores estar&atilde;o dispostos em local vis&iacute;vel na Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      Telemedicina: medicina aplicada mediante utiliza&ccedil;&atilde;o de
      metodologias interativas de comunica&ccedil;&atilde;o audiovisual e dados,
      com o objetivo de assist&ecirc;ncia, educa&ccedil;&atilde;o e pesquisa em
      Sa&uacute;de, conforme defini&ccedil;&atilde;o do art. 1&ordm; da
      Resolu&ccedil;&atilde;o CFM n&ordm; 1.643/2002.
    </p>
    <p>
      Termos de Servi&ccedil;o e Condi&ccedil;&otilde;es de Uso: Documento
      regulat&oacute;rio do uso da Plataforma <strong>CONSULTEI </strong>pelo
      Usu&aacute;rio, atrav&eacute;s do qual o Usu&aacute;rio ter&aacute;
      conhecimento detalhado dos servi&ccedil;os e condi&ccedil;&otilde;es,
      manifestando sua concord&acirc;ncia.
    </p>
    <p>
      Usu&aacute;ri : Pessoa F&iacute;sica que ter&aacute; acesso &agrave;
      Plataforma <strong>CONSULTEI</strong>.
    </p>
    <ol start="3">
      <li><strong> FORMAS DE CONTRATA&Ccedil;&Atilde;O E USO</strong></li>
    </ol>
    <p>
      3.1. Os Servi&ccedil;os prestados pela
      <strong>CONSULTEI </strong>s&atilde;o oferecidos e realizados na
      modalidade &agrave; dist&acirc;ncia ao Usu&aacute;rio, sob a forma de
      Teleconsulta mediante Assinatura Mensal ou Avulsa, cujas
      condi&ccedil;&otilde;es e valores estar&atilde;o dispon&iacute;veis em
      local de f&aacute;cil visualiza&ccedil;&atilde;o na Plataforma
      <strong>CONSULTEI.</strong>
    </p>
    <p>
      3.2. A <strong>CONSULTEI </strong>ter&aacute; liberdade de incluir nos
      Servi&ccedil;os, produtos ou servi&ccedil;os adicionais e/ou
      complementares aos contratados, durante a vig&ecirc;ncia da Assinatura
      Mensal.
    </p>
    <p>
      3.3. Os servi&ccedil;os adicionais e/ou complementares poder&atilde;o ser
      gratuitos ou n&atilde;o, sendo que aqueles n&atilde;o gratuitos somente
      ser&atilde;o inclu&iacute;dos nos Servi&ccedil;os mediante pr&eacute;via
      informa&ccedil;&atilde;o e concord&acirc;ncia expressa do Usu&aacute;rio.
    </p>
    <p>
      3.4. O acesso, pelo Usu&aacute;rio, &agrave;s Teleconsultas oferecidas
      pelos Profissionais de Sa&uacute;de vinculados &agrave;s
      Institui&ccedil;&otilde;es, na modalidade de Assinatura Mensal, se
      dar&aacute; de forma ilimitada, ressalvado o direito de a
      <strong>CONSULTEI </strong>advertir o Usu&aacute;rio que utilizar a
      plataforma <strong>CONSULTEI </strong>de forma indevida ou com abuso de
      direito.
    </p>
    <p>
      3.5. Para utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI</strong>, o Usu&aacute;rio dever&aacute; atender as
      exig&ecirc;ncias m&iacute;nimas de configura&ccedil;&atilde;o de seu
      equipamento (hardware), de sistema operacional (software), devendo ainda
      possuir uma conex&atilde;o ativa, est&aacute;vel e segura com a Internet.
    </p>
    <p>
      3.6. A exig&ecirc;ncia para utiliza&ccedil;&atilde;o do Software &eacute;
      o acesso &agrave; Plataforma <strong>CONSULTEI </strong>pelo sistema web
      ou por Aplicativo de propriedade da <strong>CONSULTEI </strong>em uma Loja
      de Aplicativos M&oacute;veis, seja a <em>Apple Store</em>,
      <em>Google Play</em> ou qualquer outra loja onde o Aplicativo do
      Usu&aacute;rio esteja dispon&iacute;vel para <em>download</em>.
    </p>
    <p>
      3.7. A <strong>CONSULTEI </strong>n&atilde;o &eacute; um conv&ecirc;nio
      m&eacute;dico, n&atilde;o sendo poss&iacute;vel realizar consultas de
      urg&ecirc;ncia/emerg&ecirc;ncia, interna&ccedil;&otilde;es hospitalares,
      procedimentos ambulatoriais ou intra-hospitalares, cirurgias eletivas ou
      de urg&ecirc;ncia, exames laboratoriais ou de imagem, tratamentos de
      reabilita&ccedil;&atilde;o motora/psicol&oacute;gica/neurol&oacute;gica ou
      qualquer atendimento m&eacute;dico/&aacute;rea da sa&uacute;de sob custeio
      pela Consultei.
    </p>
    <ol start="4">
      <li><strong> LICEN&Ccedil;A LIMITADA DE USO</strong></li>
    </ol>
    <p>
      4.1. O presente Termo e Condi&ccedil;&otilde;es de Uso regulamenta a forma
      pela qual a <strong>CONSULTEI </strong>concede ao Usu&aacute;rio uma
      licen&ccedil;a de uso da Plataforma <strong>CONSULTEI</strong>, em
      car&aacute;ter n&atilde;o exclusivo e intransfer&iacute;vel, limitado ao
      per&iacute;odo de contrata&ccedil;&atilde;o e mediante pagamento, o qual
      dever&aacute; ser utilizado em conformidade com as regras presentes destes
      Termos e Condi&ccedil;&otilde;es de Uso.
    </p>
    <p>
      4.2. A Plataforma <strong>CONSULTEI </strong>n&atilde;o &eacute; vendida
      ao Usu&aacute;rio, mas t&atilde;o somente licenciada para o seu uso, em
      conformidade com a legisla&ccedil;&atilde;o brasileira. &Eacute; vedado ao
      Usu&aacute;rio a cess&atilde;o e/ou comercializa&ccedil;&atilde;o da
      Plataforma <strong>CONSULTEI </strong>ou mesmo do seu uso, seja total ou
      parcialmente.
    </p>
    <p>
      4.3. A utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI </strong>dever&aacute; observar os termos e
      disposi&ccedil;&otilde;es do presente Termo e Condi&ccedil;&otilde;es de
      Uso. Em caso de uso n&atilde;o autorizado, o Usu&aacute;rio estar&aacute;
      sujeito a todas as penalidades previstas nestas condi&ccedil;&otilde;es
      gerais e na legisla&ccedil;&atilde;o brasileira, assim como ao pagamento
      de eventuais perdas e danos que a <strong>CONSULTEI </strong>vier a
      sofrer. Em nenhuma hip&oacute;tese o Usu&aacute;rio ter&aacute; acesso ao
      c&oacute;digo fonte dos Softwares da Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      4.4. A utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI </strong>somente ser&aacute; poss&iacute;vel
      atrav&eacute;s de conex&atilde;o de internet ativa e est&aacute;vel do
      Usu&aacute;rio, n&atilde;o se responsabilizando a
      <strong>CONSULTEI </strong>pela presta&ccedil;&atilde;o de servi&ccedil;os
      de acesso da internet, sendo de total responsabilidade do Usu&aacute;rio o
      acesso &agrave; internet com capacidade de conex&atilde;o.
    </p>
    <p>
      4.5. A <strong>CONSULTEI </strong>n&atilde;o se responsabilizar&aacute;
      por impossibilidade de utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI </strong>em caso de falta de conex&atilde;o com a
      internet, seja esta falha moment&acirc;nea ou n&atilde;o.
    </p>
    <p>
      4.6 O Usu&aacute;rio n&atilde;o adquire, pela utiliza&ccedil;&atilde;o da
      Plataforma <strong>CONSULTEI</strong>, qualquer direito de propriedade
      intelectual ou outros direitos exclusivos, incluindo patentes, desenhos,
      marcas, direitos autorais ou quaisquer direitos sobre
      informa&ccedil;&otilde;es confidenciais ou segredos de neg&oacute;cio, bem
      como todo o conte&uacute;do disponibilizado no Site, incluindo, mas
      n&atilde;o se limitando a textos, gr&aacute;ficos, imagens, logotipos,
      &iacute;cones, fotografias, conte&uacute;do editorial e qualquer outro
      material relacionado a Plataforma <strong>CONSULTEI</strong>.
    </p>
    <p>
      4.7. Todo o conte&uacute;do disponibilizado na Plataforma
      <strong>CONSULTEI</strong>, incluindo, mas n&atilde;o se limitando a,
      textos, gr&aacute;ficos, imagens, logos, &iacute;cones, fotografias,
      conte&uacute;do editorial, notifica&ccedil;&otilde;es, softwares e
      qualquer outro material &eacute; de propriedade exclusiva da
      <strong>CONSULTEI </strong>ou est&aacute; devidamente licenciado por esta,
      n&atilde;o podendo ser copiado, plagiado, reproduzido ou publicado em
      qualquer meio (f&iacute;sico ou virtual).
    </p>
    <p>
      4.8. &Eacute; vedado ao Usu&aacute;rio modificar, copiar, distribuir,
      transmitir, exibir, realizar, reproduzir, publicar, disponibilizar,
      licenciar ou criar obras derivadas a partir das informa&ccedil;&otilde;es
      coletadas na Plataforma <strong>CONSULTEI</strong>, bem como transferir ou
      utilizar para fins comerciais tais informa&ccedil;&otilde;es, softwares,
      produtos ou servi&ccedil;os, sob pena de viola&ccedil;&atilde;o deste
      instrumento e caracteriza&ccedil;&atilde;o de infra&ccedil;&atilde;o
      legal.
    </p>
    <p>
      4.9. &Eacute; reservado &agrave; <strong>CONSULTEI </strong>todos os
      direitos da Plataforma <strong>CONSULTEI</strong>.
    </p>
    <p>
      4.10. &Eacute; expressamente proibida a utiliza&ccedil;&atilde;o de
      ferramentas, scripts, softwares, rob&ocirc;s ou meios que contenham
      qualquer v&iacute;rus, worms, malwares, trojans e/ou outros programas de
      computador que possam causar danos a Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      4.11. A Plataforma <strong>CONSULTEI </strong>est&aacute; sujeita a
      constantes atualiza&ccedil;&otilde;es, modifica&ccedil;&otilde;es,
      otimiza&ccedil;&otilde;es e aperfei&ccedil;oamentos, que visam melhorar e
      tornar mais segura e eficiente a sua opera&ccedil;&atilde;o, assim como
      adequar-se &agrave;s altera&ccedil;&otilde;es na legisla&ccedil;&atilde;o.
    </p>
    <p>
      4.12. O Usu&aacute;rio, na vig&ecirc;ncia de sua licen&ccedil;a de uso,
      ter&aacute; direito de acessar as novas vers&otilde;es da Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      4.13. O Usu&aacute;rio desde j&aacute; concorda com as
      modifica&ccedil;&otilde;es, melhorias ou corre&ccedil;&otilde;es no modelo
      de Software utilizado pela <strong>CONSULTEI</strong>, ficando a
      crit&eacute;rio da <strong>CONSULTEI </strong>o gerenciamento e
      implementa&ccedil;&atilde;o dessas atualiza&ccedil;&otilde;es.
    </p>
    <ol start="5">
      <li><strong> INFORMA&Ccedil;&Otilde;ES CADASTRAIS</strong></li>
    </ol>
    <p>
      5.1 O acesso &agrave; Plataforma <strong>CONSULTEI </strong>ser&aacute;
      realizado pelo Usu&aacute;rio atrav&eacute;s do cadastro de uma conta, com
      login e senha, de uso pessoal e intransfer&iacute;vel.
    </p>
    <p>
      5.2. O cadastro e a utiliza&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI </strong>dever&atilde;o ser realizados com
      informa&ccedil;&otilde;es, declara&ccedil;&otilde;es e documentos
      v&aacute;lidos, completos e atualizados, responsabilizando-se a
      Institui&ccedil;&atilde;o e o Usu&aacute;rio, civil e criminalmente, pela
      veracidade e precis&atilde;o das informa&ccedil;&otilde;es, isentando a
      <strong>CONSULTEI </strong>e os parceiros desta por qualquer dano ou
      preju&iacute;zo que a falta de informa&ccedil;&otilde;es corretas causar.
    </p>
    <p>
      5.3. O tratamento de dados pessoais dar-se-&aacute; de acordo com as bases
      legais previstas na Lei 13.709/2018 (LGPD) e limitado &agrave;s atividades
      necess&aacute;rias para o alcance da finalidade do SERVI&Ccedil;O prestado
      ou, quando for o caso, ao cumprimento de obriga&ccedil;&atilde;o legal ou
      regulat&oacute;ria, no exerc&iacute;cio regular de direito.
    </p>
    <p>
      5.4. O Usu&aacute;rio sempre ser&aacute; integralmente respons&aacute;vel
      pela utiliza&ccedil;&atilde;o de sua conta na Plataforma
      <strong>CONSULTEI</strong>, devendo adotar as medidas de seguran&ccedil;a
      necess&aacute;rias para impedir o uso por terceiros n&atilde;o
      autorizados. Caso suspeite que um terceiro esteja utilizando indevidamente
      sua conta na Plataforma <strong>CONSULTEI</strong>, dever&aacute; efetuar
      a imediata troca de sua senha pessoal e comunicar tal fato &agrave;
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      5.5. O uso da Plataforma <strong>CONSULTEI </strong>de forma indevida,
      irregular, ilegal ou em desacordo &agrave;s condi&ccedil;&otilde;es
      previstas neste Termos e Condi&ccedil;&otilde;es de Uso implicar&aacute; o
      imediato bloqueio, com posterior exclus&atilde;o do cadastro e a
      rescis&atilde;o imediata do acesso do Usu&aacute;rio &agrave; Plataforma
      <strong>CONSULTEI</strong>, sujeito ainda &agrave;s
      comina&ccedil;&otilde;es legais.
    </p>
    <p>
      5.6. O Usu&aacute;rio ou a Institui&ccedil;&atilde;o dever&aacute;
      indenizar a <strong>CONSULTEI </strong>seus s&oacute;cios, colaboradores,
      empregados, clientes e/ou terceiros prejudicados por qualquer perda,
      reclama&ccedil;&atilde;o ou demanda, bem como por todos e quaisquer
      preju&iacute;zos resultantes da utiliza&ccedil;&atilde;o indevida da
      Plataforma <strong>CONSULTEI </strong>em discord&acirc;ncia com o disposto
      no presente instrumento e na legisla&ccedil;&atilde;o aplic&aacute;vel.
    </p>
    <ol start="6">
      <li><strong> FORMAS DE PAGAMENTO</strong></li>
    </ol>
    <p>
      6.1. A <strong>CONSULTEI </strong>aceita pagamentos atrav&eacute;s de
      cart&otilde;es de cr&eacute;dito, dentre outros de livre escolha da
      <strong>CONSULTEI </strong>que estar&atilde;o dispon&iacute;veis no site
      ou Plataforma <strong>CONSULTEI </strong>no momento da
      realiza&ccedil;&atilde;o do pagamento pelo Usu&aacute;rio.
    </p>
    <p>
      6.2. O acesso &agrave; Plataforma <strong>CONSULTEI </strong>ser&aacute;
      disponibilizado a partir do momento em que a
      <strong>CONSULTEI </strong>verificar o pagamento feito pelo
      Usu&aacute;rio. Se o pagamento n&atilde;o puder ser verificado, por
      qualquer motivo, a <strong>CONSULTEI </strong>poder&aacute;, a seu
      crit&eacute;rio, decidir n&atilde;o permitir o acesso do Usu&aacute;rio
      &agrave; Plataforma <strong>CONSULTEI </strong>at&eacute; que possa
      confirmar o pagamento efetuado pelo Usu&aacute;rio. No caso de n&atilde;o
      confirma&ccedil;&atilde;o do pagamento, a
      <strong>CONSULTEI </strong>poder&aacute; comunicar o Usu&aacute;rio por
      e-mail, SMS ou &ldquo;push&rdquo;, ferramenta de comunica&ccedil;&atilde;o
      direta do app com o usu&aacute;rio.
    </p>
    <ol start="7">
      <li><strong> SUPORTE T&Eacute;CNICO</strong></li>
    </ol>
    <p>
      7.1. A <strong>CONSULTEI </strong>dar&aacute; suporte t&eacute;cnico
      relativo ao Software remotamente, por telefone e pela Internet, no
      hor&aacute;rio comercial das 08h00min &agrave;s 18h00min, em dias
      &uacute;teis, de segunda &agrave; sexta-feira.
    </p>
    <p>
      7.2. Para os fins destes Termos e Condi&ccedil;&otilde;es de Uso,
      ser&atilde;o considerados os dias &uacute;teis do Munic&iacute;pio de
      Itapeva/SP.
    </p>
    <p>
      7.3. Eventuais atendimentos solicitados fora do hor&aacute;rio
      padr&atilde;o de atendimento, isto &eacute;, dias &uacute;teis, das
      08h00min &agrave;s 18h00min, estar&atilde;o sujeitos &agrave;
      disponibilidade da <strong>CONSULTEI </strong>e ao pagamento de quantia
      adicional, a ser devidamente informada na ocasi&atilde;o da
      solicita&ccedil;&atilde;o do servi&ccedil;o.
    </p>
    <p>
      7.4. O servi&ccedil;o de suporte t&eacute;cnico consistir&aacute; em
      t&eacute;cnicos treinados para solucionar d&uacute;vidas e orientar o
      Usu&aacute;rio na opera&ccedil;&atilde;o da Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <ol start="8">
      <li><strong> RESTRI&Ccedil;&Otilde;ES DE FUNCIONALIDADE</strong></li>
    </ol>
    <p>
      8.1. N&atilde;o &eacute; permitido acessar as &aacute;reas de
      programa&ccedil;&atilde;o da Plataforma <strong>CONSULTEI</strong>, seu
      banco de dados ou qualquer outro conjunto de informa&ccedil;&otilde;es que
      fa&ccedil;a parte da programa&ccedil;&atilde;o do sistema, realizar ou
      permitir que se realize qualquer tipo de engenharia reversa,
      tradu&ccedil;&atilde;o, decompila&ccedil;&atilde;o, c&oacute;pia,
      modifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o,
      loca&ccedil;&atilde;o, subloca&ccedil;&atilde;o, sublicenciamento,
      publica&ccedil;&atilde;o, divulga&ccedil;&atilde;o, transmiss&atilde;o,
      empr&eacute;stimo, distribui&ccedil;&atilde;o ou, de qualquer outra forma,
      a disposi&ccedil;&atilde;o de ferramentas de consulta desta plataforma e
      de suas funcionalidades para terceiros sem a pr&eacute;via e expressa
      autoriza&ccedil;&atilde;o da <strong>CONSULTEI</strong>, ficando sujeito
      quem o fizer &agrave; responsabiliza&ccedil;&atilde;o criminal e civil sob
      a legisla&ccedil;&atilde;o brasileira, sem preju&iacute;zo da
      obriga&ccedil;&atilde;o de reparar os danos que causarem. Esta
      restri&ccedil;&atilde;o inclui qualquer tentativa de incorporar quaisquer
      informa&ccedil;&otilde;es da Plataforma <strong>CONSULTEI </strong>em
      qualquer outro diret&oacute;rio, produto ou servi&ccedil;o.
    </p>
    <p>
      8.2. &Eacute; proibida a utiliza&ccedil;&atilde;o de programas do tipo
      spider/crawler ou de minera&ccedil;&atilde;o de dados, que tenham como
      finalidade a quebra de sistemas de seguran&ccedil;a, senha, captcha, ou
      ainda de qualquer tipo ou esp&eacute;cie correlato, al&eacute;m de outros
      aqui n&atilde;o tipificados, mas que atue de modo automatizado, tanto para
      realizar opera&ccedil;&otilde;es massificadas ou para quaisquer outras
      finalidades, sob pena de aplica&ccedil;&atilde;o da
      legisla&ccedil;&atilde;o correlata de prote&ccedil;&atilde;o dos
      interesses da <strong>CONSULTEI</strong>, sem preju&iacute;zo da
      obriga&ccedil;&atilde;o de reparar quaisquer perdas e danos eventualmente
      apurados e/ou suportados pela <strong>CONSULTEI </strong>e que decorrerem
      desta utiliza&ccedil;&atilde;o indevida.
    </p>
    <p>
      8.3. &Eacute; proibida ainda a coleta de quaisquer
      informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal e
      cadastro de outros Usu&aacute;rios da Plataforma
      <strong>CONSULTEI </strong>para quaisquer fins.
    </p>
    <ol start="9">
      <li><strong> OBRIGA&Ccedil;&Otilde;ES DO USU&Aacute;RIO</strong></li>
    </ol>
    <p>
      9.1. Pelo presente termo, obriga-se o Usu&aacute;rio, sem preju&iacute;zo
      das demais previstas nestes Termos e Condi&ccedil;&otilde;es de Uso e na
      legisla&ccedil;&atilde;o pertinente a:
    </p>
    <p>
      (a) respeitar as disposi&ccedil;&otilde;es deste Termo e as leis que regem
      a sua utiliza&ccedil;&atilde;o, empregando-os para a finalidade para qual
      foi destinado o servi&ccedil;o;
    </p>
    <p>
      (b) n&atilde;o divulgar a quem quer que seja seus dados de acesso, nem
      permitir o uso de tais informa&ccedil;&otilde;es, responsabilizando-se
      pelo uso de seu cadastro, uma vez que pessoais e intransfer&iacute;veis,
      devendo notificar a <strong>CONSULTEI </strong>imediatamente em caso de
      uso ou acesso n&atilde;o autorizado de sua conta;
    </p>
    <p>
      (c) para que ocorra a Teleconsulta com seguran&ccedil;a, o Usu&aacute;rio
      dever&aacute; estar conectado &agrave; rede de internet segura, em
      ambiente seguro, silencioso e privado. A rela&ccedil;&atilde;o do
      Profissional de Sa&uacute;de com o Usu&aacute;rio deve ser preservada e,
      caso haja quebra desta rela&ccedil;&atilde;o, seja por comportamento ou
      ambiente inadequado, o Profissional de Sa&uacute;de poder&aacute;
      interromper a Teleconsulta a qualquer momento.
    </p>
    <p>
      9.2. Utilizar a Plataforma <strong>CONSULTEI </strong>exclusivamente ao
      fim a que ele se destina, abstendo-se de trafegar e armazenar dados e
      informa&ccedil;&otilde;es que importem em preju&iacute;zo a terceiros;
      violem a lei, a moral, os bons costumes; a propriedade intelectual; que
      incorporem v&iacute;rus ou outros elementos f&iacute;sicos ou
      eletr&ocirc;nicos que possam danificar ou impedir o seu uso normal;
    </p>
    <p>
      9.3. Utilizar somente equipamentos h&aacute;beis e capazes de atender
      &agrave;s exig&ecirc;ncias m&iacute;nimas de configura&ccedil;&atilde;o
      necess&aacute;rias &agrave; utiliza&ccedil;&atilde;o do software, bem como
      pelo manuseio correto do programa;
    </p>
    <p>
      9.4. N&atilde;o acessar a Plataforma <strong>CONSULTEI </strong>sob
      informa&ccedil;&otilde;es de outra pessoa, utilizando CPF e n&uacute;mero
      de Celular de outro Usu&aacute;rio que n&atilde;o seja respons&aacute;vel
      legal. Caso tenha acesso a dados de Usu&aacute;rio, vedar o uso da
      Plataforma <strong>CONSULTEI</strong>, saindo imediatamente da &aacute;rea
      restrita do Usu&aacute;rio.
    </p>
    <p>
      9.5. N&atilde;o acessar, desconstituir, alterar, modificar, seccionar ou
      de qualquer forma adulterar os c&oacute;digos fonte da Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      9.6. Manter o cadastro de seu CPF e N&uacute;mero de Celular atualizados,
      de forma a impossibilitar o acesso de outra pessoa ao seu cadastro de
      Usu&aacute;rio;
    </p>
    <p>
      9.7. Manter seu computador ou dispositivo m&oacute;vel livre de
      v&iacute;rus ou similares, propiciando assim maior seguran&ccedil;a ao
      tr&aacute;fego e armazenamento dos dados/informa&ccedil;&otilde;es;
    </p>
    <p>
      9.8. Manter os seus dados cadastrais devidamente atualizados, sob pena de,
      n&atilde;o o fazendo, considerarem-se v&aacute;lidos todos os avisos e
      notifica&ccedil;&otilde;es enviados para os endere&ccedil;os constantes do
      referido cadastro;
    </p>
    <p>
      9.9. Autorizar que a <strong>CONSULTEI </strong>proceda as
      necess&aacute;rias modifica&ccedil;&otilde;es, repara&ccedil;&otilde;es e
      implementa&ccedil;&otilde;es de novos recursos &agrave; Plataforma
      <strong>CONSULTEI</strong>, caso apresente anomalia ou irregularidade,
      mesmo que acarrete sua indisponibilidade tempor&aacute;ria;
    </p>
    <p>
      9.10. Reconhecer que todas as modifica&ccedil;&otilde;es, melhorias e
      corre&ccedil;&otilde;es efetuadas na Plataforma
      <strong>CONSULTEI </strong>ser&atilde;o de propriedade da
      <strong>CONSULTEI</strong>, objeto de licen&ccedil;a de uso, nos mesmos
      termos e sujeitando-se aos presentes Termos e Condi&ccedil;&otilde;es de
      Uso;
    </p>
    <ol start="10">
      <li><strong> GARANTIAS E RESPONSABILIDADES</strong></li>
    </ol>
    <p>
      10.1. A <strong>CONSULTEI </strong>assegura a funcionalidade da Plataforma
      <strong>CONSULTEI</strong>, desde que utilizado corretamente pelo
      Usu&aacute;rio de acordo com as instru&ccedil;&otilde;es da
      <strong>CONSULTEI</strong>, pelo per&iacute;odo que durar a licen&ccedil;a
      de uso contratada.
    </p>
    <p>
      10.2. A garantia n&atilde;o ser&aacute; aplic&aacute;vel nos casos em que
      qualquer n&atilde;o conformidade ou defeito for exclusivamente causado
      por: (a) Programa de computador associado ou complementar cujo uso
      conjunto n&atilde;o tenha sido expressamente autorizado pela
      <strong>CONSULTEI; </strong>b) Modifica&ccedil;&atilde;o n&atilde;o
      autorizada ou uso da Plataforma <strong>CONSULTEI </strong>em desacordo
      com as regras e disposi&ccedil;&otilde;es deste Termo e
      Condi&ccedil;&otilde;es de Uso e/ou das instru&ccedil;&otilde;es que sejam
      fornecidas pela <strong>CONSULTEI</strong>;
    </p>
    <p>
      10.3. A <strong>CONSULTEI </strong>n&atilde;o se responsabiliza por danos
      causados ao sistema por v&iacute;rus de computador,
      interrup&ccedil;&otilde;es de energia el&eacute;trica, conex&atilde;o e
      capacidade da Internet, atualiza&ccedil;&otilde;es ou falhas inerentes ao
      servidor de hospedagem terceirizado ou qualquer outra causa que n&atilde;o
      seja associada diretamente &agrave; <strong>CONSULTEI.</strong>
    </p>
    <p>
      10.4. A <strong>CONSULTEI </strong>n&atilde;o garante que a Plataforma
      <strong>CONSULTEI </strong>esteja livre de erros. A
      <strong>CONSULTEI </strong>envidou seus maiores esfor&ccedil;os para sanar
      qualquer erro de programa&ccedil;&atilde;o que a Plataforma
      <strong>CONSULTEI </strong>venha a apresentar.
    </p>
    <p>
      10.5. Na hip&oacute;tese da ocorr&ecirc;ncia de qualquer defeito no
      software, a <strong>CONSULTEI </strong>dever&aacute; ser comunicada
      previamente para solu&ccedil;&atilde;o, n&atilde;o podendo ser
      responsabilizada sem antes ter conhecimento expresso do problema pelo
      Usu&aacute;rio.
    </p>
    <p>
      10.6. Esta garantia pressup&otilde;e que a plataforma
      <strong>CONSULTEI</strong>, objeto deste contrato, seja operada em
      ambiente de software e hardware de origem id&ocirc;nea, em sistema de rede
      e instala&ccedil;&otilde;es el&eacute;tricas adequadas e isentas de
      problemas, e dentro da configura&ccedil;&atilde;o m&iacute;nima exigida
      pela <strong>CONSULTEI</strong>. Considera-se hardware n&atilde;o
      id&ocirc;neo aquele origin&aacute;rio de fabricante desconhecido ou
      n&atilde;o legalmente constitu&iacute;do, ou montado em
      instala&ccedil;&otilde;es clandestinas, origin&aacute;rio de contrabando
      ou qualquer outro em que n&atilde;o exista garantia expressa e
      v&aacute;lida de fabrica&ccedil;&atilde;o de seu todo ou partes.
    </p>
    <p>
      10.7. As garantias estipuladas na presente cl&aacute;usula n&atilde;o
      abrangem problemas, erros, danos ou preju&iacute;zos advindos de
      decis&otilde;es tomadas com base em informa&ccedil;&otilde;es, quaisquer
      que sejam fornecidas pelo(s) programa(s), assim como n&atilde;o abrangem
      defeitos ou erros decorrentes de neglig&ecirc;ncia, imprud&ecirc;ncia ou
      imper&iacute;cia do Usu&aacute;rio na utiliza&ccedil;&atilde;o da
      Plataforma <strong>CONSULTEI</strong>, e tamb&eacute;m problemas
      provenientes de caso fortuito ou for&ccedil;a maior.
    </p>
    <p>
      10.8. O Usu&aacute;rio &eacute; integralmente respons&aacute;vel pelas
      informa&ccedil;&otilde;es inseridas na Plataforma
      <strong>CONSULTEI</strong>. Toda e qualquer informa&ccedil;&atilde;o
      inserida pelo Usu&aacute;rio n&atilde;o ser&aacute; revisada pela
      <strong>CONSULTEI,</strong> que em hip&oacute;tese alguma ser&aacute;
      respons&aacute;vel pelo seu conte&uacute;do.
    </p>
    <p>
      10.9. Em nenhuma circunst&acirc;ncia a
      <strong>CONSULTEI </strong>ser&aacute; considerada respons&aacute;vel por
      quaisquer danos, diretos ou indiretos, f&iacute;sicos, t&eacute;cnicos,
      econ&ocirc;micos, ou fiscais, tais como, sem se resumir a, perdas e danos,
      lucros cessantes, danos emergentes, interrup&ccedil;&atilde;o de
      neg&oacute;cios ou outros preju&iacute;zos decorrentes de uso ou da
      impossibilidade do uso da Plataforma <strong>CONSULTEI</strong>.
    </p>
    <p>
      10.10. Os documentos m&eacute;dicos eventualmente gerados na Teleconsulta
      (receitas, atestados, pedidos de exames e encaminhamentos) estar&atilde;o
      dispon&iacute;veis para <em>download </em>imediatamente ap&oacute;s a
      consulta, na &aacute;rea do Usu&aacute;rio dentro da Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      10.11. &Eacute; de responsabilidade da <strong>CONSULTEI </strong>garantir
      ao Usu&aacute;rio, durante 24 (vinte e quatro) horas, 07 (sete) dias por
      semana, a disponibilidade dos Profissionais de Sa&uacute;de vinculados
      &agrave;s Institui&ccedil;&otilde;es.
    </p>
    <p>
      10.12. As Institui&ccedil;&otilde;es oferecer&atilde;o ao Usu&aacute;rio,
      pelos Profissionais de Sa&uacute;de a elas vinculados, por meio da
      Plataforma <strong>CONSULTEI</strong>, especialidades m&eacute;dicas
      dispostas em locais vis&iacute;veis na Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      10.13. A Plataforma <strong>CONSULTEI </strong>ficar&aacute;
      dispon&iacute;vel pelo per&iacute;odo de 24 (vinte e quatro) horas por dia
      para a realiza&ccedil;&atilde;o das consultas m&eacute;dicas &agrave;
      dist&acirc;ncia e online prestadas pelas Institui&ccedil;&otilde;es, por
      meio dos Profissionais de Sa&uacute;de a elas vinculados.
    </p>
    <ol start="11">
      <li>
        <strong>
          LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE DA CONSULTEI</strong>
      </li>
    </ol>
    <p>
      11.1. O Usu&aacute;rio desde j&aacute; declara conhecer e concordar que a
      Plataforma <strong>CONSULTEI </strong>&eacute; t&atilde;o somente uma
      plataforma web ou por Aplicativo, que facilita a interconex&atilde;o entre
      as Institui&ccedil;&otilde;es, os Profissionais de Sa&uacute;de a ela
      vinculados e os Usu&aacute;rios, a quem disponibiliza funcionalidades para
      auxiliar a realiza&ccedil;&atilde;o de Teleconsulta pelos Profissionais de
      Sa&uacute;de.
    </p>
    <p>
      11.2 A Plataforma <strong>CONSULTEI </strong>n&atilde;o &eacute;
      respons&aacute;vel pela disponibilidade, qualidade e
      manuten&ccedil;&atilde;o de tais servi&ccedil;os de
      telecomunica&ccedil;&atilde;o e o Usu&aacute;rio entende que as
      condi&ccedil;&otilde;es do servi&ccedil;o de
      telecomunica&ccedil;&otilde;es poder&atilde;o afetar a experi&ecirc;ncia
      do Servi&ccedil;o. A <strong>CONSULTEI </strong>n&atilde;o ser&aacute;
      responsabilizada por qualquer problema relacionado ao Servi&ccedil;o
      decorrente direta ou indiretamente de inconsist&ecirc;ncias ou falhas nos
      dispositivos de telefonia m&oacute;vel e/ou nos servi&ccedil;os de
      telecomunica&ccedil;&atilde;o. Al&eacute;m disso, a
      <strong>CONSULTEI </strong>n&atilde;o ser&aacute; responsabilizada por
      eventuais erros e inconsist&ecirc;ncias das informa&ccedil;&otilde;es
      oriundas de terceiros relativas ao geoposicionamento via sistema GPS e aos
      mapas integrados ao sistema.
    </p>
    <p>
      11.3. Em fun&ccedil;&atilde;o do disposto no item supra a
      <strong>CONSULTEI </strong>n&atilde;o se responsabilizar&aacute;:
    </p>
    <p>
      (a) Pela falha de opera&ccedil;&atilde;o, opera&ccedil;&atilde;o por
      pessoas n&atilde;o autorizadas ou qualquer outra causa em que n&atilde;o
      exista culpa da <strong>CONSULTEI</strong>;
    </p>
    <p>
      (b) ocorr&ecirc;ncias por &ldquo;caso fortuito&rdquo; ou
      &ldquo;for&ccedil;a maior&rdquo;;
    </p>
    <p>
      (c) problemas decorrentes de a&ccedil;&otilde;es de terceiros que possam
      interferir na qualidade do servi&ccedil;o;
    </p>
    <p>
      (d) danos causados a terceiros em raz&atilde;o de servi&ccedil;os
      contratados e conte&uacute;do gerado atrav&eacute;s da Plataforma
      <strong>CONSULTEI</strong>;
    </p>
    <p>
      (e) por revisar as informa&ccedil;&otilde;es fornecidas pelos
      Usu&aacute;rios, Institui&ccedil;&otilde;es e Profissionais de
      Sa&uacute;de, seja no que tange &agrave; veracidade, exatid&atilde;o ou
      completude dos dados, legalidade ou viola&ccedil;&atilde;o legal em
      fun&ccedil;&atilde;o do fornecimento destas informa&ccedil;&otilde;es;
    </p>
    <p>
      (f) danos pessoais ou qualquer preju&iacute;zo incidental, especial,
      indireto ou consequente, incluindo, sem limita&ccedil;&atilde;o,
      preju&iacute;zos por perda de lucro, corrup&ccedil;&atilde;o ou perda de
      dados, falha de transmiss&atilde;o ou recep&ccedil;&atilde;o de dados,
      n&atilde;o continuidade do neg&oacute;cio ou qualquer outro
      preju&iacute;zo decorrentes ou relacionados ao seu uso ou inabilidade do
      Usu&aacute;rio, Institui&ccedil;&otilde;es e Profissionais de Sa&uacute;de
      a elas vinculados que utilizarem a Plataforma <strong>CONSULTEI</strong>;
    </p>
    <p>
      (g) qualquer fraude, declara&ccedil;&atilde;o fraudulenta ou
      viola&ccedil;&atilde;o do dever, ou viola&ccedil;&atilde;o de qualquer uma
      das condi&ccedil;&otilde;es deste Termo e Condi&ccedil;&otilde;es de Uso
      pelo Usu&aacute;rio, Institui&ccedil;&otilde;es e Profissionais de
      Sa&uacute;de a elas vinculados;
    </p>
    <p>
      (h) falhas de conex&otilde;es, especialmente no que tange a
      impossibilidade de acesso &agrave; internet pelos Usu&aacute;rios; e
    </p>
    <p>
      (i) quaisquer problemas relacionados &agrave; presta&ccedil;&atilde;o de
      servi&ccedil;os realizada pelas Institui&ccedil;&otilde;es e os
      Profissionais de Sa&uacute;de a elas vinculados aos Usu&aacute;rios.
    </p>
    <p>
      11.4. A <strong>CONSULTEI </strong>envidar&aacute; seus melhores
      esfor&ccedil;os para manter o seu conte&uacute;do atualizado e completo,
      livre de quaisquer defeitos ou v&iacute;rus, n&atilde;o se
      responsabilizando, contudo, por esses e quaisquer outros poss&iacute;veis
      problemas que n&atilde;o sejam causados diretamente e exclusivamente pela
      <strong>CONSULTEI</strong>. Fica desde j&aacute; acordado que a
      <strong>CONSULTEI </strong>poder&aacute; alterar o conte&uacute;do a
      qualquer momento, sem pr&eacute;vio aviso.
    </p>
    <p>
      11.5. A <strong>CONSULTEI </strong>declara inexist&ecirc;ncia de
      rela&ccedil;&atilde;o de trabalho, v&iacute;nculo de emprego e outros
      entre as Institui&ccedil;&otilde;es e os Profissionais de Sa&uacute;de a
      elas vinculados com a <strong>CONSULTEI</strong>, bem como
      inexist&ecirc;ncia de qualquer v&iacute;nculo de natureza
      societ&aacute;ria, empregat&iacute;cia e/ou econ&ocirc;mica. A
      <strong>CONSULTEI </strong>n&atilde;o possui uma rede de Profissionais de
      Sa&uacute;de ou Institui&ccedil;&otilde;es, prestando exclusivamente os
      servi&ccedil;os de licenciamento e intermedia&ccedil;&atilde;o voltados
      &agrave; interconex&atilde;o <em>online</em> entre as
      Institui&ccedil;&otilde;es, os Profissionais de Sa&uacute;de a elas
      vinculados e os Usu&aacute;rios que tiverem acesso &agrave; Plataforma
      <strong>CONSULTEI</strong>.
    </p>
    <p>
      11.6. A <strong>CONSULTEI </strong>n&atilde;o se responsabiliza por
      quaisquer perdas, preju&iacute;zos ou danos de qualquer natureza que sejam
      decorrentes da rela&ccedil;&atilde;o entre Usu&aacute;rio,
      Institui&ccedil;&otilde;es e os Profissionais de Sa&uacute;de a elas
      vinculados. O Usu&aacute;rio entende e concorda que a
      <strong>CONSULTEI </strong>n&atilde;o ser&aacute; respons&aacute;vel por
      quaisquer danos ou preju&iacute;zos que venham a lhe ser causados pelas
      Institui&ccedil;&otilde;es e os Profissionais de Sa&uacute;de a elas
      vinculados, uma vez que a rela&ccedil;&atilde;o entre os Profissionais de
      Sa&uacute;de e os Usu&aacute;rios &eacute; regida pelos preceitos e normas
      estabelecidos pelo CFM (Conselho Federal de Medicina).
    </p>
    <p>
      11.7. A Teleconsulta ser&aacute; realizada por Profissional de
      Sa&uacute;de vinculado &agrave; Institui&ccedil;&otilde;es, que se
      responsabilizam integral e solidariamente pelo atendimento realizado,
      respondendo civilmente, criminalmente e perante os respectivos conselhos
      de classe por eventuais danos causados.
    </p>
    <ol start="12">
      <li><strong> CANCELAMENTO</strong></li>
    </ol>
    <p>
      12.1. O cancelamento dos Servi&ccedil;os poder&aacute; ser requerido pelo
      Usu&aacute;rio, observado o prazo m&iacute;nimo de 12 (doze) meses a
      partir da libera&ccedil;&atilde;o do acesso pelo Usu&aacute;rio &agrave;
      Plataforma <strong>CONSULTEI,</strong> para que o cancelamento se d&ecirc;
      sem quaisquer &ocirc;nus ou multa para o Usu&aacute;rio.
    </p>
    <p>
      12.2. Se o Usu&aacute;rio, sem justa causa, decidir por cancelar os
      Servi&ccedil;os antes do prazo a que se refere o item 12.1., ficar&aacute;
      obrigado a pagar, a t&iacute;tulo de multa por quebra contratual, o
      equivalente a 04 (quatro) Assinaturas Mensais.
    </p>
    <p>
      12.3. Se o Usu&aacute;rio decidir cancelar os Servi&ccedil;os da
      <strong>CONSULTEI </strong>ap&oacute;s 03 (tr&ecirc;s) meses do seu
      in&iacute;cio, ficar&aacute; obrigada a pagar, em favor da outra, os
      valores proporcionais at&eacute; o fim do prazo a que se refere o item
      12.1.
    </p>
    <p>
      12.4. Os pagamentos efetuados pelos Servi&ccedil;os n&atilde;o s&atilde;o
      pass&iacute;veis de reembolso, inclusive pelo cancelamento unilateral do
      Usu&aacute;rio.
    </p>
    <ol start="13">
      <li><strong> POL&Iacute;TICA DE PRIVACIDADE</strong></li>
    </ol>
    <p>
      13.1. O Usu&aacute;rio concorda que a <strong>CONSULTEI </strong>lhe envie
      ou simplesmente disponibilize, por meio da Plataforma
      <strong>CONSULTEI</strong>, artigos, textos, noticias ou quaisquer outras
      formas de comunica&ccedil;&atilde;o, desenvolvidas pela pr&oacute;pria
      <strong>CONSULTEI </strong>ou por parceiros desta, sempre que julgar que
      tais comunica&ccedil;&otilde;es sejam de interesse do Usu&aacute;rio.
    </p>
    <p>
      13.2. A <strong>CONSULTEI </strong>poder&aacute; modificar este Termo de
      Condi&ccedil;&otilde;es e Uso a qualquer momento, publicando a nova
      vers&atilde;o em seu website. Os novos Termos e Condi&ccedil;&otilde;es de
      Uso revisados entrar&atilde;o em vigor dentro de 10 (dez) dias contados da
      data de sua publica&ccedil;&atilde;o ou entrega ao Usu&aacute;rio, exceto
      se o Usu&aacute;rio expressamente aceitar o contrato revisado antes disso
      ao clicar no bot&atilde;o de aceitar. A aceita&ccedil;&atilde;o expressa
      ou o uso cont&iacute;nuo da Plataforma <strong>CONSULTEI </strong>depois
      da expira&ccedil;&atilde;o do prazo de 10 (dez) dias do aviso
      constituir&aacute; aceita&ccedil;&atilde;o integral destes Termos e
      Condi&ccedil;&otilde;es de Uso.
    </p>
    <p>
      13.3. As disposi&ccedil;&otilde;es destes Termos e Condi&ccedil;&otilde;es
      de Uso s&atilde;o irrevog&aacute;veis e irretrat&aacute;veis e obrigam as
      partes e seus sucessores a qualquer t&iacute;tulo. As partes reconhecem,
      para todos os fins e efeitos de direito, que os presentes Termos e
      Condi&ccedil;&otilde;es de Uso constituem t&iacute;tulo executivo
      extrajudicial, estando sujeito &agrave; execu&ccedil;&atilde;o
      espec&iacute;fica, nos termos do disposto no C&oacute;digo de Processo
      Civil.
    </p>
    <p>
      13.4. Fica eleito o foro da Comarca de Itapeva/SP, com ren&uacute;ncia de
      qualquer outro, por mais privilegiado que seja, para resolver qualquer
      quest&atilde;o resultante do presente Termo e Condi&ccedil;&otilde;es de
      Uso.
    </p>
    <p><br /><br /></p>
  </ng-container>

  <ng-template #termosMedico>
    <p style="text-align: center;">
      <strong>TERMO DE USO DO M&Eacute;DICO E DO PROFISSIONAL DA SA&Uacute;DE</strong>
    </p>
    <ol>
      <li>
        <strong>INTRODU&Ccedil;&Atilde;O E DISPOSI&Ccedil;&Otilde;ES GERAIS</strong>
      </li>
    </ol>
    <p>
      1.1. Bem-vindo &agrave;
      <strong>Consultei Telemedicina e Tecnologia LTDA</strong> &ndash;
      &ldquo;<strong>Consultei</strong>&rdquo;. Este &eacute; o Termo de Uso que
      rege o acesso e uso, dentro do Brasil, dos servi&ccedil;os prestados, por
      meio de aplica&ccedil;&atilde;o tecnol&oacute;gica e s&iacute;tios da
      internet (&ldquo;Aplicativo&rdquo;), disponibilizados pela
      <strong>Consultei</strong>, inscrita no CNPJ sob o n&ordm;
      44.627.285/0001-60, com sede na Rua Santana, 176 &ndash; Centro &ndash;
      Itapeva/SP, 18.400-010, ou qualquer de suas afiliadas, ao(s)
      M&eacute;dico(s) e Profissional(is) da Sa&uacute;de, conforme
      descri&ccedil;&atilde;o abaixo, que se cadastrem e sejam aprovados para
      utilizar os Servi&ccedil;os da Consultei.
    </p>
    <p>
      (i) <strong>M&eacute;dicos</strong>: profissionais formados em medicina,
      devidamente regularizados para o exerc&iacute;cio da profiss&atilde;o em
      sua respectiva especialidade e sujeitos ao Conselho Regional e Federal de
      Medicina (CRM/CFM).
    </p>
    <p>
      &nbsp;(ii) <strong>Profissionais da Sa&uacute;de</strong>: profissionais
      relacionados &agrave; &aacute;rea da sa&uacute;de. Neste Termo,
      ser&atilde;o considerados como Profissionais da Sa&uacute;de:
      Psic&oacute;logos, Nutricionistas, Fisioterapeutas e Enfermeiros,
      devidamente regularizados para o exerc&iacute;cio da sua profiss&atilde;o
      e sujeitos ao Conselho Regional e Federal de Psicologia (CRP/CFP),
      Conselho Regional e Federal de Nutricionistas (CRN/CFN), Conselho Regional
      e Federal de Fisioterapia e Terapia Ocupacional (CREFITO/COFFITO) e
      Conselho Regional e Federal de Enfermagem (COREN/COFEN), bem como demais
      profissionais e seus respectivos conselhos.
    </p>
    <p>
      &nbsp;(iii) <strong>Pacientes</strong>: benefici&aacute;rios de
      determinada operadora de sa&uacute;de ou seguradora que tem contrato de
      presta&ccedil;&atilde;o de servi&ccedil;os de pronto atendimento digital
      (PA Digital) com a Consultei.
    </p>
    <p>
      &nbsp;1.1.1 Neste Termo, quando M&eacute;dicos e Profissionais de
      Sa&uacute;de forem tratados conjuntamente ser&aacute; adotado o termo
      &ldquo;Profissionais&rdquo;.
    </p>
    <p>
      &nbsp;1.1.1.1 POR FAVOR, LEIA COM ATEN&Ccedil;&Atilde;O ESTE TERMO ANTES
      DE USAR OS SERVI&Ccedil;OS.&nbsp;
    </p>
    <p>
      1.2. ACEITA&Ccedil;&Atilde;O DO TERMO DE USO DO M&Eacute;DICO E DO
      PROFISSIONAL DA SA&Uacute;DE. AO SE CADASTRAR, SER APROVADO E UTILIZAR OS
      SERVI&Ccedil;OS, OS PROFISSIONAIS ESTAR&Atilde;O DECLARANDO TER LIDO,
      ENTENDIDO E ACEITO AS CONDI&Ccedil;&Otilde;ES PREVISTAS NESTE TERMO. CASO,
      A QUALQUER TEMPO, OS PROFISSIONAIS N&Atilde;O CONCORDEM COM AS
      CONDI&Ccedil;&Otilde;ES ESTABELECIDAS NESTE TERMO, DEVER&Atilde;O CESSAR
      IMEDIATAMENTE A UTILIZA&Ccedil;&Atilde;O DO APLICATIVO E
      DESINSTAL&Aacute;-LO DE SEU APARELHO, SENDO CERTO QUE O PRESENTE TERMO
      SER&Aacute; CONSIDERADO V&Aacute;LIDO DURANTE TODO O PER&Iacute;ODO EM QUE
      O APLICATIVO FOI UTILIZADO.
    </p>
    <p>1.2.1. Termos Adicionais</p>
    <p>
      Termos adicionais poder&atilde;o ser aplicados a determinados
      Servi&ccedil;os, tais como condi&ccedil;&otilde;es para modalidades de
      teleatendimento, triagem, acompanhamento pela enfermagem, atendimento
      domiciliar (ou na localidade do Paciente), encaminhamento a m&eacute;dicos
      especialistas, seja no &acirc;mbito do&nbsp;<em>Cuidado Coordenado</em>,
      seja no &acirc;mbito da presta&ccedil;&atilde;o dos servi&ccedil;os da
      Consultei e, encaminhamento ao pronto socorro, na modalidade&nbsp;<em>Fast Track</em>&nbsp;ou encaminhamentos em
      geral. Esses Termos ser&atilde;o divulgados
      em rela&ccedil;&atilde;o aos respectivos Servi&ccedil;os (&ldquo;Termos
      Adicionais&rdquo;), sendo complementares e considerados parte integrante
      deste Termo.&nbsp;
    </p>
    <p>
      Em caso de conflito, os Termos Adicionais prevalecer&atilde;o sobre este
      Termo somente em rela&ccedil;&atilde;o ao que forem espec&iacute;ficos.
    </p>
    <p>
      1.3. Comunica&ccedil;&atilde;o com o M&eacute;dico e o Profissional da
      Sa&uacute;de&nbsp;
    </p>
    <p>
      1.3.1.Os Profissionais autorizam previamente a Consultei a enviar
      notifica&ccedil;&otilde;es administrativas no Aplicativo
      (&ldquo;<em>push</em>&rdquo;), por e-mail, SMS, em uma
      publica&ccedil;&atilde;o em seu site ou por qualquer outro meio de
      comunica&ccedil;&atilde;o dispon&iacute;vel (&ldquo;Meios de
      Comunica&ccedil;&atilde;o&rdquo;) com conte&uacute;do de natureza
      informativa e promocional relacionado aos Servi&ccedil;os.
    </p>
    <p>1.4. Altera&ccedil;&atilde;o dos Termos&nbsp;</p>
    <p>
      1.4.1.A Consultei reserva o direito de realizar altera&ccedil;&otilde;es e
      atualiza&ccedil;&otilde;es no Termo, a qualquer momento, sem a necessidade
      de aviso pr&eacute;vio. Em caso de altera&ccedil;&otilde;es do Termo que
      restrinja direitos dos Profissionais, a Consultei poder&aacute; comunicar
      tal altera&ccedil;&atilde;o, a seu crit&eacute;rio, atrav&eacute;s dos
      Meios de Comunica&ccedil;&atilde;o. Os Profissionais se responsabilizam em
      verificar periodicamente o Termo para ci&ecirc;ncia de eventuais
      altera&ccedil;&otilde;es. Caso os Profissionais n&atilde;o concordem,
      dever&atilde;o cancelar a conta, cessar toda e qualquer
      utiliza&ccedil;&atilde;o dos Servi&ccedil;os e desinstalar o Aplicativo
      dos seus respectivos aparelhos. O fato de os Profissionais continuarem a
      acessar ou usar os Servi&ccedil;os, ap&oacute;s essa postagem, configura o
      consentimento inequ&iacute;voco em vincular-se ao Termo alterado.
    </p>
    <ol>
      <li>
        <strong>DADOS DO M&Eacute;DICO E DO PROFISSIONAL DA SA&Uacute;DE E
          PRIVACIDADE</strong>
      </li>
    </ol>
    <p>
      2.1. A Consultei possui uma pol&iacute;tica expressa sobre privacidade,
      parte integrante deste Termo, cujo acesso poder&aacute; ocorrer por meio
      do link&nbsp;<a
        href="https://docway.com.br/politica-de-privacidade/">https://Consultei.com.br/politica-de-privacidade/</a>. As
      informa&ccedil;&otilde;es de registro sobre os Profissionais e
      outras necess&aacute;rias &agrave; execu&ccedil;&atilde;o dos
      Servi&ccedil;os est&atilde;o sujeitas ao tratamento previsto na referida
      pol&iacute;tica de privacidade. Os Profissionais concordam com a coleta e
      utiliza&ccedil;&atilde;o das informa&ccedil;&otilde;es definidas na
      pol&iacute;tica de privacidade, para fins de armazenamento, processamento
      e utiliza&ccedil;&atilde;o pela Consultei, sua Controladora e demais
      empresas do mesmo grupo econ&ocirc;mico, para envio &agrave;s autoridades
      competentes, em caso de obriga&ccedil;&atilde;o legal.&nbsp;
    </p>
    <ol>
      <li><strong>CADASTRO</strong></li>
    </ol>
    <p>
      3.1. Para utilizar os Servi&ccedil;os, os Profissionais devem registrar-se
      no Aplicativo ou via website e manter apenas uma conta pessoal destinada
      aos Profissionais (&ldquo;Conta&rdquo;). Os Profissionais devem ter
      capacidade civil e habilita&ccedil;&atilde;o t&eacute;cnica no respectivo
      conselho de classe, bem como possuir smartphone, tablet ou computador
      compat&iacute;vel com o Aplicativo e dever&atilde;o cumprir todos os
      requisitos legais correspondentes ao local de sua atua&ccedil;&atilde;o
      para exerc&iacute;cio da medicina no local da presta&ccedil;&atilde;o dos
      Servi&ccedil;os e respectiva especializa&ccedil;&atilde;o. Em caso de
      n&atilde;o confirma&ccedil;&atilde;o de seus dados, o acesso dos
      Profissionais ao Servi&ccedil;o poder&aacute; ser bloqueado, a exclusivo
      crit&eacute;rio da Consultei.
    </p>
    <p>
      3.2. Em caso de pessoa jur&iacute;dica, esta dever&aacute; estar
      totalmente regular para o seu funcionamento e cumprir as regras previstas
      neste deste Termo.
    </p>
    <p>
      3.3. Ap&oacute;s receber a documenta&ccedil;&atilde;o de cadastro, seja
      via Aplicativo, seja via web, a Consultei efetuar&aacute; an&aacute;lise
      das informa&ccedil;&otilde;es prestadas e documentos encaminhados, podendo
      aceitar ou recusar a solicita&ccedil;&atilde;o de cadastro dos
      Profissionais, a seu exclusivo crit&eacute;rio. A Consultei tamb&eacute;m
      poder&aacute; realizar a checagem a respeito de eventual
      especializa&ccedil;&atilde;o informada, antecedentes criminais e quaisquer
      outras verifica&ccedil;&otilde;es que considerar oportunas ou que sejam
      exigidas pela legisla&ccedil;&atilde;o aplic&aacute;vel.
    </p>
    <p>
      3.4. O perfil dos Profissionais &eacute; exclusivo e
      intransfer&iacute;vel. Os Profissionais comprometem-se, mediante
      aceita&ccedil;&atilde;o do Termo, a n&atilde;o compartilhar sua Conta com
      terceiros, sendo vedada a transfer&ecirc;ncia, sob pena de cancelamento
      imediato, al&eacute;m de encaminhamento do caso &agrave;s autoridades
      p&uacute;blicas para an&aacute;lise de penalidades criminais e civis
      aplic&aacute;veis.&nbsp;
    </p>
    <p>
      3.4.1. A Consultei se reserva o direito de solicitar documentos adicionais
      para confirma&ccedil;&atilde;o de cadastros, bem como outros
      m&eacute;todos de identifica&ccedil;&atilde;o e autentica&ccedil;&atilde;o
      dos Profissionais como, por exemplo, por ocasi&atilde;o do cadastro e
      enquanto os Profissionais utilizarem os Servi&ccedil;os a qualquer tempo,
      observados os direitos relativos &agrave; privacidade de dados pessoais.
      Mesmo ap&oacute;s a confirma&ccedil;&atilde;o do cadastro, &eacute;
      poss&iacute;vel o cancelamento da Conta caso sejam verificadas
      incongru&ecirc;ncias no processo de verifica&ccedil;&atilde;o, a exclusivo
      crit&eacute;rio da Consultei.&nbsp;
    </p>
    <p>
      3.4.2. As informa&ccedil;&otilde;es da Conta s&atilde;o de exclusiva
      responsabilidade de quem as inseriu. No caso de acarretarem danos ou
      preju&iacute;zos de qualquer esp&eacute;cie, as medidas cab&iacute;veis
      podem ser tomadas pela Consultei a fim de resguardar seus interesses e a
      integridade de Pacientes, demais Profissionais do Aplicativo e da
      pr&oacute;pria Consultei.&nbsp;
    </p>
    <p>
      3.5. Os Profissionais se comprometem, sob as penas da lei, a utilizar sua
      Conta do Aplicativo apenas para fins estritamente legais e permitidos por
      este Termo.
    </p>
    <p>
      3.6. Em caso de pessoa jur&iacute;dica, esta dever&aacute; fazer o
      cadastro dos Profissionais a serem habilitados para o uso do Aplicativo.
    </p>
    <ol>
      <li><strong>SERVI&Ccedil;OS</strong></li>
    </ol>
    <p>
      4.1. Os Servi&ccedil;os a serem prestados pelos M&eacute;dicos
      credenciados e/ou Profissionais da Sa&uacute;de aos Pacientes indicados
      pela Consultei, por meio dos respectivos estipulantes, consistem na
      triagem, teleatendimento, acompanhamento pela enfermagem, atendimento
      domiciliar, encaminhamento a m&eacute;dicos especialistas, seja no
      &acirc;mbito do&nbsp;<em>Cuidado Coordenado</em>, seja no &acirc;mbito da
      presta&ccedil;&atilde;o dos servi&ccedil;os da Consultei e, encaminhamento
      ao pronto socorro, na modalidade&nbsp;<em>Fast Track</em>&nbsp;ou
      encaminhamentos em geral.
    </p>
    <p>
      4.2. A Consultei n&atilde;o se responsabiliza por quaisquer danos sofridos
      pelos Profissionais em raz&atilde;o de c&oacute;pia, transfer&ecirc;ncia,
      distribui&ccedil;&atilde;o ou qualquer outra forma de
      utiliza&ccedil;&atilde;o de conte&uacute;do, por terceiros, protegido
      disponibilizado no Aplicativo.&nbsp;
    </p>
    <p>
      4.3. A Consultei&nbsp;aqui &eacute; a &uacute;nica titular do Aplicativo e
      dos direitos de propriedade intelectual da&iacute; decorrentes.
    </p>
    <p>
      4.4. A Consultei poder&aacute; oferecer, a seu crit&eacute;rio, produtos
      vinculados ou n&atilde;o ao Servi&ccedil;o, bem como poder&aacute; prestar
      outros servi&ccedil;os, gratuitos ou onerosos, conforme acordado com os
      Profissionais.
    </p>
    <p>
      4.5. As informa&ccedil;&otilde;es n&atilde;o devem ser utilizadas para
      qualquer finalidade que n&atilde;o as expressamente autorizadas por este
      Termo.&nbsp;
    </p>
    <ol>
      <li><strong>DA INEXIST&Ecirc;NCIA DE V&Iacute;NCULO</strong></li>
    </ol>
    <p>
      5.1. N&atilde;o se estabelece entre os Profissionais e a Consultei
      qualquer v&iacute;nculo de natureza societ&aacute;ria, empregat&iacute;cia
      e/ ou econ&ocirc;mica, sendo certo que os Profissionais s&atilde;o livres
      para cessar a sua utiliza&ccedil;&atilde;o do Aplicativo a qualquer
      momento, ao seu livre e exclusive crit&eacute;rio. A Consultei n&atilde;o
      possui uma rede de m&eacute;dicos ou profissionais de sa&uacute;de,
      disponibilizando a sua Plataforma para a intermedia&ccedil;&atilde;o na
      presta&ccedil;&atilde;o dos&nbsp;servi&ccedil;os de teleatendimento por
      M&eacute;dicos e dos Profissionais de Sa&uacute;de cadastrados no
      Aplicativo.&nbsp;
    </p>
    <ol>
      <li><strong>CANAIS DE ATENDIMENTO&nbsp;</strong></li>
    </ol>
    <p>
      6.1.A Consultei disponibilizar&aacute; canais de atendimento para
      solucionar d&uacute;vidas e problemas dos usu&aacute;rios do Aplicativo,
      pelo canal &ldquo;suporte@Consultei.com.br&rdquo;, no hor&aacute;rio de
      7:00 &agrave;s 22:00 h, observado o disposto na Cl&aacute;usula 7.2,
      abaixo.&nbsp;
    </p>
    <p>
      6.2. A Consultei n&atilde;o ser&aacute; respons&aacute;vel por quaisquer
      perdas, preju&iacute;zos e/ou danos de qualquer natureza que sejam
      decorrentes da rela&ccedil;&atilde;o entre Paciente e Profissionais.&nbsp;
    </p>
    <p>
      6.3. A Consultei n&atilde;o ser&aacute; respons&aacute;vel por quaisquer
      danos ou preju&iacute;zos que venham a ser causados por Paciente aos
      Profissionais.
    </p>
    <p>
      6.4. Os Profissionais ser&atilde;o respons&aacute;veis pelos danos ou
      preju&iacute;zos que vier a causar ao(s) Paciente(s) ou terceiros e
      concordam em indenizar e manter a Consultei indene em
      rela&ccedil;&atilde;o a quaisquer demandas, perdas, preju&iacute;zos ou
      danos direta ou indiretamente relacionados a atos, fatos ou
      omiss&otilde;es por eles causados. Os Profissionais s&atilde;o os
      &uacute;nicos e exclusivos respons&aacute;veis por todos e quaisquer
      problemas relativos ao Servi&ccedil;o de atendimento &agrave;
      sa&uacute;de, bem como por quaisquer condutas indevidas ou ilegais que
      pratique.&nbsp;
    </p>
    <p>
      6.5. A Consultei n&atilde;o se responsabilizar&aacute; por consultas
      desmarcadas pelos Profissionais. E, caso ocorra reclama&ccedil;&atilde;o,
      demanda ou penalidade, a este t&iacute;tulo, os Profissionais
      assumir&atilde;o com exclusividade responsabilidade.
    </p>
    <ol>
      <li><strong>ACESSO &Agrave; REDE E EQUIPAMENTOS&nbsp;</strong></li>
    </ol>
    <p>
      7.1. Os Profissionais entendem e concordam que a utiliza&ccedil;&atilde;o
      da Plataforma demanda a aquisi&ccedil;&atilde;o de dispositivos de
      telefonia m&oacute;vel e a contrata&ccedil;&atilde;o de servi&ccedil;os de
      telecomunica&ccedil;&atilde;o compat&iacute;veis com o uso do Aplicativo,
      e que a utiliza&ccedil;&atilde;o do Servi&ccedil;o poder&aacute; gerar
      cobran&ccedil;as por parte de tais prestadores de servi&ccedil;o de
      telecomunica&ccedil;&atilde;o para conex&atilde;o com a Internet, por
      exemplo. Os Profissionais s&atilde;o os respons&aacute;veis exclusivos por
      contratar e arcar com todo e qualquer custo e &ocirc;nus relacionados
      &agrave; aquisi&ccedil;&atilde;o de seu dispositivo de telefonia
      m&oacute;vel e a contrata&ccedil;&atilde;o de seu servi&ccedil;o de
      telecomunica&ccedil;&atilde;o.&nbsp;
    </p>
    <p>
      7.2. A Consultei n&atilde;o &eacute; respons&aacute;vel pela
      disponibilidade, qualidade e manuten&ccedil;&atilde;o de tais
      servi&ccedil;os de telecomunica&ccedil;&atilde;o e os Profissionais
      entendem que as condi&ccedil;&otilde;es do servi&ccedil;o de
      telecomunica&ccedil;&otilde;es poder&atilde;o afetar a experi&ecirc;ncia
      do Servi&ccedil;o. A Consultei n&atilde;o ser&aacute; responsabilizada por
      qualquer problema relacionado ao Servi&ccedil;o decorrente direta ou
      indiretamente de inconsist&ecirc;ncias ou falhas nos dispositivos de
      telefonia m&oacute;vel e/ou nos servi&ccedil;os de
      telecomunica&ccedil;&atilde;o. Al&eacute;m disso, a Consultei n&atilde;o
      ser&aacute; responsabilizada por eventuais erros e inconsist&ecirc;ncias
      das informa&ccedil;&otilde;es oriundas de terceiros relativas ao
      geoposicionamento, via sistema GPS, e aos mapas integrados ao
      Aplicativo.&nbsp;
    </p>
    <ol>
      <li>
        <strong>MODIFICA&Ccedil;&Atilde;O OU ENCERRAMENTO DO SERVI&Ccedil;O</strong>
      </li>
    </ol>
    <p>
      8.1. A Consultei reserva o direito de, a qualquer tempo, modificar ou
      descontinuar, tempor&aacute;ria ou permanentemente, o Servi&ccedil;o ou
      parte dele, com ou sem notifica&ccedil;&atilde;o pr&eacute;via. Os
      Profissionais concordam que a Consultei n&atilde;o ser&aacute;
      responsabilizada, nem ter&aacute; qualquer obriga&ccedil;&atilde;o
      adicional, impl&iacute;cita ou expl&iacute;cita, para com os Profissionais
      ou terceiros em raz&atilde;o de qualquer modifica&ccedil;&atilde;o,
      suspens&atilde;o ou desativa&ccedil;&atilde;o do Servi&ccedil;o.
    </p>
    <ol>
      <li>
        <strong>OBRIGA&Ccedil;&Otilde;ES DO M&Eacute;DICO E DO PROFISSIONAL DA
          SA&Uacute;DE</strong>
      </li>
    </ol>
    <p>
      9.1. Os Profissionais devem respeitar as regras e condi&ccedil;&otilde;es
      deste Termo e da legisla&ccedil;&atilde;o aplic&aacute;vel,
      comprometendo-se a:&nbsp;
    </p>
    <ol>
      <li>
        a) Agir perante a Consultei e aos Paciente com boa-f&eacute;,
        dilig&ecirc;ncia, profissionalismo e respeito;
      </li>
      <li>
        b) Obedecer a todas as exig&ecirc;ncias legais e regulat&oacute;rias
        referentes aos Servi&ccedil;os de atendimento &agrave; sa&uacute;de,
        incluindo as leis, regulamentos e demais normas aplic&aacute;veis em
        &acirc;mbito federal, estadual e municipal, observando sempre os
        C&oacute;digos de &Eacute;tica dos respectivos Conselhos de Classe; de
        cada conselho de classe;
      </li>
      <li>
        c) Responsabilizar-se integralmente pela presta&ccedil;&atilde;o do
        servi&ccedil;o de atendimento &agrave; sa&uacute;de de Pacientes;&nbsp;
      </li>
      <li>
        d)&nbsp;N&atilde;o divulgar login e senha, impedindo o uso de tais
        informa&ccedil;&otilde;es por terceiros e responsabilizando-se pelas
        consequ&ecirc;ncias pelo acesso indevido.
      </li>
      <li><strong>DA SUSPEITA DE FRAUDE&nbsp;</strong></li>
    </ol>
    <p>
      10.1. A Consultei n&atilde;o se responsabiliza por quaisquer condutas
      fraudulentas por parte dos Profissionais, podendo inclusive impedir, em
      raz&atilde;o delas, seu acesso ao Aplicativo, bem como agir judicial ou
      extrajudicialmente contra o envolvido.
    </p>
    <ol>
      <li>
        <strong>SUSPENS&Atilde;O E CANCELAMENTO DE ACESSO AO APLICATIVO</strong>
      </li>
    </ol>
    <p>
      11.1. Os Profissionais concordam que a Consultei, &agrave; sua livre
      discri&ccedil;&atilde;o, poder&aacute; suspender ou cancelar sua
      utiliza&ccedil;&atilde;o do Aplicativo, incluindo, mas n&atilde;o se
      limitando: (i) por descredenciamento dos Profissionais; (ii)
      descumprimentos e/ou viola&ccedil;&atilde;o deste Termo ou Termos de
      Servi&ccedil;os espec&iacute;ficos; (iii) em fun&ccedil;&atilde;o de ordem
      judicial ou requisi&ccedil;&atilde;o legal de autoridade p&uacute;blica
      competente; (iv) por requisi&ccedil;&atilde;o dos Profissionais; (v) por
      desativa&ccedil;&atilde;o ou modifica&ccedil;&atilde;o dos Servi&ccedil;os
      (ou de qualquer de suas partes); (vi) por caso fortuito, for&ccedil;a
      maior e/ou quest&otilde;es de seguran&ccedil;a; (vii) por inatividade da
      Conta pelo per&iacute;odo de 365 (trezentos e sessenta e cinco) dias
      corridos; (viii) pela suposta pr&aacute;tica de infra&ccedil;&atilde;o,
      atividade fraudulenta ou ilegal por parte dos Profissionais, a
      crit&eacute;rio da Consultei; (ix) pelo uso inadequado ou abusivo do
      Aplicativo, incluindo a utiliza&ccedil;&atilde;o por terceiros ou
      transfer&ecirc;ncia de sua Conta, utiliza&ccedil;&atilde;o de quaisquer
      aplicativos ou programas que visem alterar a informa&ccedil;&atilde;o da
      localiza&ccedil;&atilde;o geogr&aacute;fica do M&eacute;dico e do
      Profissional da Sa&uacute;de para manipular o Aplicativo, e outras
      hip&oacute;teses de uso indevido ou abusivo do Aplicativo, a
      crit&eacute;rio da Consultei.
    </p>
    <p>
      11.2. Os Profissionais n&atilde;o far&atilde;o jus a qualquer
      indeniza&ccedil;&atilde;o ou compensa&ccedil;&atilde;o, seja pela
      suspens&atilde;o ou cancelamento da Conta.
    </p>
    <ol>
      <li><strong>PROPRIEDADE INTELECTUAL</strong></li>
    </ol>
    <p>
      12.1. As marcas, nomes, logotipos, nomes de dom&iacute;nio e demais sinais
      distintivos, bem como todo e qualquer conte&uacute;do, desenho, arte
      ou&nbsp;<em>layout</em>&nbsp;publicado no Aplicativo, o Aplicativo, em si
      e o c&oacute;digo-fonte do software e eventuais
      deriva&ccedil;&otilde;es,&nbsp;s&atilde;o de propriedade exclusiva da
      Consultei.
    </p>
    <p>
      12.2. S&atilde;o vedados quaisquer atos ou contribui&ccedil;&otilde;es
      tendentes &agrave; descompila&ccedil;&atilde;o, engenharia reversa,
      modifica&ccedil;&atilde;o das caracter&iacute;sticas,
      amplia&ccedil;&atilde;o, altera&ccedil;&atilde;o, mesclagem ou
      incorpora&ccedil;&atilde;o em quaisquer outros programas ou sistemas do
      Aplicativo ou Servi&ccedil;o. Enfim, toda e qualquer forma de
      reprodu&ccedil;&atilde;o do Aplicativo ou Servi&ccedil;o, total ou
      parcial, permanente, tempor&aacute;ria ou provis&oacute;ria, de forma
      gratuita ou onerosa, sob quaisquer modalidades, formas ou t&iacute;tulos
      &eacute; expressamente vedada.
    </p>
    <ol>
      <li><strong>CONFIDENCIALIDADE</strong></li>
    </ol>
    <p>
      13.1 Os Profissionais obrigam-se a manter a confidencialidade e sigilo
      relativos a quaisquer informa&ccedil;&otilde;es, dados, documentos,
      projetos e quaisquer outros materiais obtidos em raz&atilde;o do presente
      Termo, sejam informa&ccedil;&otilde;es dos Pacientes ou
      informa&ccedil;&otilde;es da Consultei, sendo vedada sua
      utiliza&ccedil;&atilde;o em benef&iacute;cio pr&oacute;prio ou de
      terceiros.&nbsp;
    </p>
    <ol>
      <li><strong>DISPOSI&Ccedil;&Otilde;ES FINAIS</strong></li>
    </ol>
    <p>
      14.1. Caso a Consultei deixe de exercer ou executar qualquer direito ou
      dispositivo deste Termo, n&atilde;o ser&aacute; caracterizado como
      ren&uacute;ncia a tal direito ou dispositivo, tampouco
      nova&ccedil;&atilde;o. Se qualquer dispositivo do Termo for considerado
      inv&aacute;lido por um tribunal competente, as Partes todavia concordam
      que o restante do Termo dever&aacute; ser interpretado de tal forma a
      refletir a inten&ccedil;&atilde;o original, permanecendo os demais
      dispositivos em pleno vigor e efeito.
    </p>
    <p>
      14.2. &Eacute; vedada a cess&atilde;o de direitos e
      obriga&ccedil;&otilde;es prevista neste Termo.
    </p>
    <p>
      14.3. As condi&ccedil;&otilde;es de contrata&ccedil;&atilde;o
      estar&atilde;o firmadas em contrato e dever&atilde;o ser obedecidas
      rigorosamente entre as Partes.
    </p>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="warn" mat-dialog-close (click)="onRefuse()">Recusar</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="onAccept()"
    cdkFocusInitial>Aceitar</button>
</mat-dialog-actions>