import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function confirmPasswordValidator(oppositeFieldName: string, targetFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let valid = true;

    if(control.parent) {
      valid = control.parent.get(oppositeFieldName).value === control.value;

      if(oppositeFieldName !== targetFieldName) {
        return valid ? null : {noMatch: {value: 'As senhas são diferentes'}};
      }

      control.parent.get(targetFieldName).setErrors(valid ? null : {noMatch: {value: 'As senhas são diferentes'}});
    } 

    return null;
  };
}