import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UiService } from '../services/ui-service';
import { LoginService } from './login.service';
import { Utils } from '../shared/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hidePassword = true;
  showingProgressBar = false;
  redirectPath = '';
  isMobile$: Observable<boolean>;
  showPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private uiService: UiService,
    private spinner: NgxSpinnerService,
  ) {}


  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

  }

  login() {
    if(!this.loginForm.value.login || !this.loginForm.value.password) {
      return;
    }

    let login = this.loginForm.value.login;

    if(!Utils.hasArroba(login)) {
      login = Utils.RemoveSpecialCharacters(login);
    }

    this.spinner.show('global');

    this.loginService.login(login, this.loginForm.value.password).subscribe((v: any) => {
      this.loginService.profile().subscribe(async (user: any) => {
        this.loginForm.get('password').reset();
        this.loginService.setUser(user);

        if(+user.full[0].isActive === 0) {
          this.spinner.hide('global');
          
          return this.uiService.showSwal(
            'Usuário inativo', 
            'O usuário está inativo, favor entrar em contato com o administrador.',
            false, 
            'error'
          ).then(v =>{
            this.loginService.logout().subscribe();
            this.loginService.setUser(null);
            location.reload();
          });
        }
        
        user.first_access = parseInt(user.first_access) === 1 ? true : false;
        
          if(user.first_access){
            this.spinner.hide('global');
            this.router.navigate(['/activate']);
          }
          else{
            this.spinner.hide('global');
            if(Number(user.type) == 3 || Number(user.type) == 4){
              this.router.navigate(['/dashboard']);
            } else{
              this.router.navigate(['/','home']);
            }
          }
        // }
      });
      }, (err: any) =>{
        this.loginForm.get('password').reset();
        this.spinner.hide('global');
        this.uiService.showSwal("Erro ao logar", "Credenciais incorretas e/ou email inexistente", false, "error");
      }
    );
  }
}
