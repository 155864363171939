import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    constructor(
        private http: HttpClient,
    ) { }

    get token() {
        return localStorage.getItem('access_token');
    }

    get header() {
        const token = this.token;
        return of({
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${token ? token : ''}`
            }
        });
    }

    get headerJson() {
        const token = this.token;
        return of({
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${token ? token : ''}`
            }
        });
    }
}