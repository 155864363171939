import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot }
    from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { map, switchMap, take } from 'rxjs/operators';
import { LoginService } from './login.service';
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private spinner: NgxSpinnerService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.isAuthenticated(state.url);
    }

    async isAuthenticated(path) {
        this.spinner.show('global');
        const tokentime = parseInt(localStorage.getItem('time_token'));
        const unixtime = ((new Date().getTime()) / 1000);

        if (unixtime < (tokentime + (3600 * 36))) {
            try {
                const profile = await firstValueFrom(this.loginService.profile());
                if (parseInt(profile.first_access) === 1) {
                    this.spinner.hide('global');
                    this.router.navigateByUrl('activate');
                    return false;
                }
                else {
                    this.loginService.setUser(profile);
                    this.spinner.hide('global');
                    return true;
                }
            } catch (error) {
                this.spinner.hide('global');
                this.router.navigateByUrl('');
                return true;
            }
        }
        else {
            this.spinner.hide('global');
            this.router.navigateByUrl('');
        }
    }

}