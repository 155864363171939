/* eslint-disable no-prototype-builtins */
import { FormControl } from "@angular/forms";
import { DateTime } from "luxon";
import VMasker from 'vanilla-masker';

const CPF_FORMAT = '999.999.999-99';
const CNPJ_FORMAT = '99.999.999/9999-99';
// 
export class Utils {

  static cpfLength = 11;
  static cnpjLength = 14;

  static GetFilledValues<T>(value: T, useFormData = false) {
    const body = useFormData ? new FormData : new URLSearchParams;

    Object.keys(value).forEach(v => {
      if (value[v] === null) return;

      if (value[v] === 'EMPTY') {
        body.set(v, '');
      }
      else if (value[v] !== '') {
        body.set(v, value[v]);
      }
    });

    return body;
  }
  static hasArroba(value) {
    return value.indexOf('@') > -1;
  }

  static CompareElements(e1, e2) {
    Object.keys(e1).forEach(e => {
      if (e1[e] !== e2[e]) {
        return false;
      }
    });

    return true;
  }

  static ObjectToAray(object) {
    const result: string[] = [];

    Object.keys(object).forEach(k => {
      result.push(object[k]);
    });

    return result;
  }

  static validateDateOfBirth(control: FormControl): { [key: string]: any } | null {
    const date = DateTime.fromFormat(control.value, 'dd/MM/yyyy');

    if (date > DateTime.now()) {
      return { 'invalidDateOfBirth': true };
    }
    if (!date.isValid) {
      return { 'invalidDateOfBirth': true };
    }

    return null;
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static TestValue(value) {
    return !((value == null) ||
      (value.hasOwnProperty('length') && value.length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0));
  }

  static FormatNumber(value: any, currencyCode = 'BRL', symbolDisplay = true, digits = true): any {
    if (isNaN(value)) {
      return value;
    }

    const numValue: number = parseFloat(value);

    let result = new Intl.NumberFormat('pt-BR', {
      style: 'currency', currency:
        currencyCode
    }).format(numValue);

    if (digits == false) {
      result = result.substring(0, result.length - 3);
    }

    return (symbolDisplay ? result : result.replace('R$', '').trim());
  }

  static OnlyNumber(field: string) {
    return field.replace(/[_\W]+/g, '');
  }

  static RemoveSpecialCharacters(field: string) {
    return field.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  static isRGValid(rg) {
    rg = this.RemoveSpecialCharacters(rg);
    if (rg.length < 9) {
      return false;
    }
    return true;
  }

  static IsCGCValid(cgc) {
    const _cgc = this.RemoveSpecialCharacters(cgc);

    const result = this.validate(_cgc);

    return result == null;
  }

  private static validate(c): any {
    const cpfCnpj = c.replace(/\D/g, '');
    if ([this.cpfLength, this.cnpjLength].indexOf(cpfCnpj.length) < 0) {
      return { length: true };
    }

    if (/^([0-9])\1*$/.test(cpfCnpj)) {
      return { equalDigits: true };
    }
    const cpfCnpjArr: number[] = cpfCnpj.split('').reverse().slice(2);
    cpfCnpjArr.unshift(this.buildDigit(cpfCnpjArr));
    cpfCnpjArr.unshift(this.buildDigit(cpfCnpjArr));

    if (cpfCnpj !== cpfCnpjArr.reverse().join('')) {
      return { digit: true };
    }

    return null;
  }

  private static buildDigit(arr: number[]): number {
    const isCpf = arr.length < this.cpfLength;
    const digit = arr
      .map((val, idx) => val * ((!isCpf ? idx % 8 : idx) + 2))
      .reduce((total, current) => total + current) % this.cpfLength;
    if (digit < 2 && isCpf) {
      return 0;
    }
    return digit < 2 ? 0 : this.cpfLength - digit;
  }

  public static IsEmailValid(email: string) {
    return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email.trim());
  }

  public static FormatCGC(doc) {
    const mask = this.RemoveSpecialCharacters(doc).length > 11 ? CNPJ_FORMAT : CPF_FORMAT;

    return VMasker.toPattern(doc, mask);
  }
  public static FormatCreditCard(value) {
    value = value.replace(/[_\W]+/g, '');

    let mask = '9999 999999 9999';

    if (value.length == 15) {
      mask = '9999 999999 99999';
    }
    else if (value.length > 15) {
      mask = '9999 9999 9999 9999';
    }

    return VMasker.toPattern(value, mask);
  }

  public static FormatRG(rg) {
    rg = this.RemoveSpecialCharacters(rg);

    const mask = '99.999.999-9';

    return VMasker.toPattern(rg, mask);
  }

  public static FormatCEP(cep) {
    cep = this.OnlyNumber(cep);

    const mask = '99999-999';

    return VMasker.toPattern(cep, mask);
  }

  public static FormatTelephone(telephone) {
    telephone = this.OnlyNumber(telephone);
    // if (telephone.length === 10) {
    //   return VMasker.toPattern(telephone, '(99) 9999-9999')
    // } else if (telephone.length === 8) {
    //   return VMasker.toPattern(telephone, '9999-9999')
    // } else {
    return VMasker.toPattern(telephone, '(99) 99999-9999');
    // }
  }

  public static validaCEP(cep) {
    if (!cep) return false;
    cep = cep.replace(/[^0-9.]/g, '');
    if (cep.length === 8)
      return true;
  }

  public static validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  }

  public static convertStringToObjectKey(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_").toLowerCase();
  }

  public static generateRandomString(length) {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";

    let text = "";

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  public static monthTranslate(month) {
    switch (month) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
    }
  }
}
